import React from 'react';
import styles from './HowItWorks.module.css';
import CTAButton from "../../components/CTAButton/CTAButton";

const HowItWorks = () => {
  return (
    <div
      className={styles.HIWContainer}
      style={{
        background: `radial-gradient(40% 30% at center bottom 100px, rgba(255, 255, 255, 0.00) 100%, #fbfbfb 100%), url(/assets/img/hiw.png) 0 0 / cover no-repeat`
      }}
    >
      <div className={styles.headerContainer}>
        <div className={styles.subheaderText}>
          <div className={styles.headerText}>It's really simple...</div>
          How it works
        </div>
        <div className={styles.btnContainer}>
          <CTAButton label="Try it out for free" onClick={() => window.scrollTo(0, 0)}/>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.line} />
        <div className={styles.stepsContainer}>
          <div className={styles.box}>
            <div className={styles.ellipse}>
              <span className={styles.span}>1</span>
            </div>
            <div className={styles.step}>
              <div className={styles.stepTitle}>Upload</div>
              <div className={styles.stepText}>Drag & drop an HTML, ZIP or PDF into the upload box</div>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.ellipse}>
              <span className={styles.span}>2</span>
            </div>
            <div className={styles.step}>
              <div className={styles.stepTitle}>Customise</div>
              <div className={styles.stepText}>Type in a link-name or leave it blank</div>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.ellipse}>
              <span className={styles.span}>3</span>
            </div>
            <div className={styles.step}>
              <div className={styles.stepTitle}>Publish</div>
              <div className={styles.stepText}>Hit the Upload button to get a shareable link</div>
            </div>
          </div>
        </div>
        <div className={styles.stepVideo}>
          <img className="d-none d-md-block box-shadow round-20" src="/assets/img/demo.gif" height={400}/>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
