import React from 'react';

import './TemplateSelector.css';

const templates = [
  {
    name: 'Landing Page',
    filename: 'lp-example.zip',
    imgSrc: '/assets/img/lp-template.jpg'
  },
  {
    name: 'Pitchdeck',
    filename: 'pitchdeck.pdf',
    imgSrc: '/assets/img/pitchdeck.png'
  },
  {
    name: 'Document Collection',
    filename: 'document-collection.zip',
    imgSrc: '/assets/img/document-collection.png'
  },
  {
    name: 'Web app',
    filename: 'webapp.zip',
    imgSrc: '/assets/img/webapp.png'
  },
  {
    name: 'Flyer',
    filename: 'flyer.pdf',
    imgSrc: '/assets/img/flyer.png'
  }
]

const TemplateSelector = ({onClick}) => {
  return <div className="template-selector-container pt-3 pb-5">
    {templates.map(({ name, filename, imgSrc }) =>
      <div
        className="template-selector-option p-2 round-8 cursor-pointer tr-template-option"
        key={filename}
        onClick={() => {
          const fileType = filename.endsWith('.zip') ? 'zip' : filename.endsWith('.pdf') ? 'pdf' : 'zip'; // Default to zip if file type is not recognized
          const type = fileType === 'zip' ? 'application/zip' : 'application/pdf';
          onClick({
            path: filename,
            type: type,
            isTemplate: true
          });
        }}
      >
      <img className="template-selector-option-img" src={imgSrc} height="130" width="150"/>
      <div className="mt-2 grey">{name}</div>
    </div>)}
  </div>
}

export default TemplateSelector;