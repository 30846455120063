import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { Alert, Button, Form, Spinner, Modal } from 'react-bootstrap'
import { refund } from '../services/refund'
import { useHistory, useLocation } from 'react-router-dom';

const schema = Yup.object({
  notes: Yup.string().min(50, 'Please be as detailed as possible (minimum 50 characters)')
})

const STAGE_INITIAL = 0
const STAGE_SUCCESS = 1
const STAGE_ERROR = 2

const Refund = () => {
  const [stage, setStage] = useState(STAGE_INITIAL)
  const [isUploading, setIsUploading] = useState(false)

  const history = useHistory();
 
  const location = useLocation();
  const [showRefundModal, setShowRefundModal] = useState(false);

  useEffect(() => {
    // Check if the current pathname is '/manage/refund'
    if (location.pathname === '/manage/refund') {
      setShowRefundModal(true);
    } else {
      setShowRefundModal(false);
    }
  }, [location]);

  const onSubmit = async ({ notes }) => {
    setIsUploading(true)

    const formData = new FormData()
    formData.append('notes', notes)

    const headers = {}

    refund(formData, headers)
      .then((res) => {
        if (res.data.success) {
          setStage(STAGE_SUCCESS)
          // reset form?
        } else {
          setStage(STAGE_ERROR)
        }
      })
      .catch(() => {
        setStage(STAGE_ERROR)
      })
      .finally(() => setIsUploading(false))
  }

  const handleClose = () => {
    setStage(STAGE_INITIAL)
    history.push('/manage');
    setShowRefundModal(false)
  }

  return (
    <Modal show={showRefundModal} onHide={handleClose}  aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Header closeButton>
        <Modal.Title>Reason for refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {stage === STAGE_ERROR && !isUploading && (
          <Alert variant="danger">
            <h5>Oops, something went wrong. Please try again.</h5>
          </Alert>
        )}
        {stage === STAGE_SUCCESS && (
          <div>
            <p>Thank you, your refund will be processed by support soon.</p>
            <Button onClick={handleClose}>Done</Button>
          </div>
        )}
        {stage !== STAGE_SUCCESS && (
          <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ notes: '' }}>
            {({ handleSubmit, handleChange, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="dark-grey"><b>Sorry that Tiiny didn't work out for you.</b>
                  <br/> We're always looking to improve the product.</div>
                <Form.Control
                  className="mt-3"
                  name="notes"
                  type="text"
                  as="textarea"
                  rows={5}
                  minLength={50}
                  placeholder="Please explain why we didn't work out for you in detail..."
                  value={values.notes}
                  onChange={handleChange}
                  disabled={isUploading}
                />
                <ErrorMessage name="notes" component="div" className="validation-error" />
                <Button
                  className="me-auto mt-3 float-right"
                  type="submit"
                  disabled={isUploading || values.notes.length === 0}
                >
                  {isUploading ? <Spinner animation="border" variant="light" size="sm" /> : 'Submit'}
                </Button>
              </form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Refund
