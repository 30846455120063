import PRO_PLAN_IN from './depricated/pro-in'
import PRO_ULTIMATE_PLAN from './pro'
import PRO_ULTIMATE_PLAN_2 from './depricated/pro-u-2'
import PRO_ULTIMATE_PLAN_3 from './depricated/pro-u-3'
import PRO_ULTIMATE_PLAN_IN from './depricated/pro-u-in'
import PRO_STUDENT from './pro-student'
import TINY_PLAN from './tiny'
import SOLO_PLAN from "./solo";

export const PERIOD_MONTH = 'monthly'
export const PERIOD_YEAR = 'yearly'
const convertToBytes = (size) => 1024 * 1024 * size

export const FREE_PLAN = {
  prices: {
    yearly: { value: 0 },
    monthly: { value: 0 }
  },
  label: 'Free',
  projectLimit: 1,
  fileSizeLimit: 3,
  visitorLimit: 5000,
  bandwidthLimit: 5
}

export const PRO_MAX_FILE_SIZES = {
  [TINY_PLAN.id]: TINY_PLAN.fileSizeLimit,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN.fileSizeLimit,
  [PRO_ULTIMATE_PLAN_2.id]: PRO_ULTIMATE_PLAN_2.fileSizeLimit,
  [PRO_ULTIMATE_PLAN_3.id]: PRO_ULTIMATE_PLAN_3.fileSizeLimit,
  [PRO_STUDENT.id]: PRO_STUDENT.fileSizeLimit,
  [SOLO_PLAN.id]: SOLO_PLAN.fileSizeLimit
}

export const PRO_CUSTOM_DOMAIN_LIMIT = {
  [TINY_PLAN.id]: TINY_PLAN.customDomainsLimit,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN.customDomainsLimit,
  [PRO_ULTIMATE_PLAN_2.id]: PRO_ULTIMATE_PLAN_2.customDomainsLimit,
  [PRO_ULTIMATE_PLAN_3.id]: PRO_ULTIMATE_PLAN_3.customDomainsLimit,
  [PRO_STUDENT.id]: PRO_STUDENT.customDomainsLimit,
  [SOLO_PLAN.id]: SOLO_PLAN.customDomainsLimit
}

export const PLANS = {
  FREE: FREE_PLAN,
  TINY: TINY_PLAN,
  PRO: SOLO_PLAN, // Solo
  PRO_U: PRO_ULTIMATE_PLAN, // Pro
  PRO_STUDENT: PRO_STUDENT
}

export const PLANS_ID = {
  [TINY_PLAN.id]: TINY_PLAN,
  [SOLO_PLAN.id]: SOLO_PLAN,
  [PRO_ULTIMATE_PLAN.id]: PRO_ULTIMATE_PLAN,
  [PRO_STUDENT.id]: PRO_STUDENT
}

export const PLANS_IN = {
  PRO: PRO_PLAN_IN,
  PRO_U: PRO_ULTIMATE_PLAN_IN,
  PRO_STUDENT: PRO_STUDENT
}

export const labelToPlanMap = {
  "Solo": "soloPlan",
  "Tiny": "tinyPlan",
  "Pro": "proPlan",
  "Free": "freePlan"
}

export const PLAN_THRESHOLDS = {
  FREE: {
    projectLimit: FREE_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(FREE_PLAN.fileSizeLimit),
    visitorLimit: FREE_PLAN.visitorLimit,
    bandwidthLimit: FREE_PLAN.bandwidthLimit,
  },
  TINY: {
    projectLimit: TINY_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(TINY_PLAN.fileSizeLimit),
    visitorLimit: TINY_PLAN.visitorLimit,
    bandwidthLimit: TINY_PLAN.bandwidthLimit,
  },
  SOLO: {
    projectLimit: SOLO_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(SOLO_PLAN.fileSizeLimit),
    visitorLimit: SOLO_PLAN.visitorLimit,
    bandwidthLimit: SOLO_PLAN.bandwidthLimit,
  },
  PRO: {
    projectLimit: PRO_ULTIMATE_PLAN.projectLimit,
    fileSizeLimit: convertToBytes(PRO_ULTIMATE_PLAN.fileSizeLimit),
    visitorLimit: PRO_ULTIMATE_PLAN.visitorLimit,
    bandwidthLimit: PRO_ULTIMATE_PLAN.bandwidthLimit,
  },
};