import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup, Dropdown, Spinner, DropdownButton } from 'react-bootstrap'
import OverflowMenu from '../OverflowMenu/OverflowMenu'
import IconButton from '../IconButton'
import {
  copyToClipboard,
  getDirectFileLink,
  hasFeature,
  sortDuplicateSites,
  sortLoadingSites,
  sortOnlineSites
} from '../../utils/general'
import { setDraggedFile, toggleModal } from 'Manage/actions'
import UpgradeBadge from '../UpgradeBadge/UpgradeBadge'
import { F_EDIT_MODE } from '../../constants/plans/constants'
import { ACTION_CREATE, ACTION_UPDATE, FILE_TYPE_PDF } from '../CreateUpdateSiteModal/interface'
import { API_ROOT } from '../../constants'
import { getSafeFileName } from 'utils/file'
import { ToolTip } from '../ProPlans/PlanCards'
import './LiveSitesTable.css'
import { getSites } from 'services/v2/pro'
import CustomTooltip from "../CustomTooltip";
import { setShowPaymentModal } from 'actions'
import { PLANS } from "constants/plans";

const getDomain = (siteRecord) => siteRecord.subdomain || siteRecord.link

function getDirectFileLinkForSite(s) {
  if (s.linkStatus != 'processing' && s.linkStatus !== 'error')
    if (s.type === FILE_TYPE_PDF) {
      return `https://${getDomain(s)}/${getSafeFileName(s.filename)}`
    } else {
      // legacy links
      return getDirectFileLink(`https://${getDomain(s)}`, s.filename, 'pdf', s.lastUpdated || s.created)
    }
}

const FeaturedCell = ({ fileType, siteType, onEditPDF, onEditHTML, onEditText, onAnalytics, directFileLink, authEditPdf, disabled }) => {
  const showPDFEditBtn = ['application/pdf', 'PDF'].includes(fileType) && directFileLink;
  const showHTMLEditBtn = ['HTML', 'ZIP', 'EXAMPLE', 'text/html', 'application/zip'].includes(fileType) && directFileLink && !siteType?.includes('TEXT');
  const showTEXTEditBtn = siteType?.includes('TEXT') && directFileLink;
  const phpFile = directFileLink?.includes('tiiny.io')

  return (
    <div className="text-end">
      {showPDFEditBtn && (
        <button
          className={`analytics-button ${
            disabled && 'disabled-button-important'
          } bg-purple-light white tr-edit-pdf-btn`}
          onClick={authEditPdf}
          disabled={disabled}
        >
          <img className="mb-1" src="/assets/icons/edit-white.svg" height={14} width={14} />
          <span className="analytics-button-label ms-1">Edit PDF</span>
        </button>
      )}
      {!phpFile && showHTMLEditBtn && (
        <button
          className={`analytics-button ${
            disabled && 'disabled-button-important'
          } bg-purple-light white tr-edit-pdf-btn`}
          onClick={() => onEditHTML(directFileLink)}
          disabled={disabled}
        >
          <img className="mb-1" src="/assets/icons/edit-white.svg" height={14} width={14} />
          <span className="analytics-button-label ms-1">Edit site</span>
        </button>
      )}
      {showTEXTEditBtn && (
        <button
          className="analytics-button bg-purple-light white tr-edit-site-btn"
          onClick={() => onEditText(directFileLink)}
        >
          <img className="mb-1" src="/assets/icons/edit-white.svg" height={14} width={14} />
          <span className="analytics-button-label ms-1">Edit text</span>
        </button>
      )}
      {!phpFile && (
        <button
          className={`analytics-button tr-analytics-btn ${disabled && 'disabled-button-important'}`}
          onClick={onAnalytics}
          disabled={disabled}
        >
          {!showPDFEditBtn && !showHTMLEditBtn && !showTEXTEditBtn &&
            <span className="analytics-button-label me-2">
              View analytics
            </span>}
          <img src="/assets/icons/analytics.png" height="16px" alt="analytics" />
        </button>
      )}
    </div>
  );
};

const StatusCell = ({ passwordProtected, siteStatus }) => (
  <div className="ps-3 pe-2 text-center">
      {passwordProtected ? (
        <div className="d-flex flex-row align-items-end gap-1 my-2 justify-start">
          <img src="lock-link.svg" height="16px" alt="Password protected" title="Password protected"/>
        </div>
      ) : (
        <div className="d-flex align-items-end justify-center">{getStatusIcon(siteStatus)}</div>
      )}
  </div>
)

const getStatusIcon = (status) => {
  switch (status) {
    case 'processing':
      return <CustomTooltip label="Processing">
        <Spinner animation="grow" variant="secondary" size="sm" />
      </CustomTooltip>
    case 'error':
      return <CustomTooltip label="Failed to upload">
        <img src="/assets/icons/circle-red.svg" height="14px" alt="live"/>
      </CustomTooltip>
    default:
      return <img src="/assets/icons/circle-green.svg" height="14px" alt="live" />
  }
}

const DomainCell = ({ label, href, directLink, trial, site, handlePreviewClick, disabled, onUpgrade }) => {
  const onDomainClick = () => {
    if (site?.linkPreview) {
      handlePreviewClick(site)
    } else {
      window.open(href, '_blank')
    }
  }
  const updatedLabel = !site?.linkPreview ? label : 'Click to preview'

  const onDirectLinkClick = () => window.open(directLink, '_blank')

  return (
    <div>
      <Dropdown className="domain-button" as={ButtonGroup} drop="end">
        <Button
          variant={`outline-secondary text-start domain-button-label ${trial ? 'trial' : ''}`}
          onClick={onDomainClick}
          disabled={disabled}
        >
          <span className="link">{updatedLabel}</span>
        </Button>
        {['PDF', FILE_TYPE_PDF].includes(site.type) && (
          <>
            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />
            <Dropdown.Menu>
              <Dropdown.Item >
                <button className="font-14 border-0 bg-transparent" onClick={onDirectLinkClick} disabled={trial}>
                  View download PDF link
                  <ToolTip text="For use in ChatGPT & other apps" />
                </button>
                <UpgradeBadge show={trial} onUpgrade={onUpgrade}/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </>
        )}
      </Dropdown>
    </div>
  )
}

const LiveSitesTable = ({
  sites,
  onSiteAction,
  userProfile,
  onArchive,
  onDelete,
  onDownload,
  onAnalytics,
  onQRCode,
  onEditPDF,
  onEmbed,
  onPreview,
  onShare,
  onOpenTextEditor
}) => {
  const dispatch = useDispatch()
  const [allSites, setAllSites] = useState(sites)
  const [onlineSites, setOnlineSites] = useState([])
  const [loadingSites, setLoadingSites] = useState([])
  const [webhook, setWebhook] = useState(false)
  const [webhookId, setWebhookId] = useState('')

  /** Webhook for processing sites */
  useEffect(() => {
    return () => {
      if (webhook === true) deleteWebhook()
    }
  }, [])

  useEffect(() => {
    setAllSites(sites)
  }, [sites])

  useEffect(() => {
    setLoadingSites(sortLoadingSites(allSites))
  }, [allSites])

  useEffect(() => {
    if (loadingSites?.length > 0) {
      setWebhook(true)
    } else if (loadingSites?.length === 0) {
      setWebhook(false)
    }
  }, [loadingSites])

  useEffect(() => {
    if (onlineSites?.length > 0) {
      setAllSites(sortDuplicateSites(allSites, onlineSites))
      setOnlineSites([])
    }
  }, [onlineSites])

  useEffect(() => {
    if (webhook === true) {
      createWebhook(loadingSites)
    } else if (webhook === false) {
      deleteWebhook()
    }
  }, [webhook])

  const getSiteWebhook = async () => {
    const { data } = await getSites({ sites: loadingSites })

    if (data?.sites) {
      let myOnlineSites = []

      data.sites.map((site) => {
        if (site.linkStatus === 'online' || site.linkStatus === 'error') {
          myOnlineSites.push(site)
        }
      })

      setLoadingSites(sortLoadingSites(data.sites))

      if (myOnlineSites.length > 0) {
        setOnlineSites(myOnlineSites)
      }
    }
  }

  const createWebhook = () => {
    const intervalId = setInterval(getSiteWebhook, 20000)

    setWebhookId(intervalId)
  }

  const deleteWebhook = () => {
    clearInterval(webhookId)
    setWebhookId('')
  }

  const handleUpdateClick = (s) => {
    dispatch(setDraggedFile(null))
    dispatch(toggleModal(true))
    onSiteAction(ACTION_UPDATE, s)
  }

  const onEditHTML = (domain, isTrial) => {
    window.open(`https://tiiny.host/editor/?domain=${domain}&file=/index.html${isTrial ? '&trial=true' : ''}`, '_blank')
  }

  const handleEditPdfWithPSWD = (password, fileLink) => {
    if (password) {
      const token = btoa(password)
      const proxyUrl = `${API_ROOT}/set-cookie-and-proxy?url=${encodeURIComponent(fileLink)}&token=${encodeURIComponent(
        token
      )}`
      onEditPDF(proxyUrl)
    } else {
      onEditPDF(fileLink)
    }
  }
  const onEditText = (site) => {
    onOpenTextEditor(site)
  }

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({showPaymentModal: true, upgradePlanId: planId}))
 }

  return (
    <div className="box-100-scrolless">
      {allSites.length > 0 ? (
        <>
          <div className="scroll-container desktop-table">
            <table>
              <thead>
                <tr>
                  <th className="table-header" style={{ width: '45px' }} />
                  <th className="table-header" style={{ minWidth: '150px', width: '100%', maxWidth: '250px' }}>
                    Project
                  </th>
                  <th className="table-header" style={{ minWidth: '155px', width: '150px' }} />
                  <th className="table-header" style={{ minWidth: '80px' }}>
                    UPDATED
                  </th>
                  <th className="table-header" style={{ minWidth: '100px', width: '100px' }}>
                    ACTIONS
                  </th>
                </tr>
              </thead>
              <tbody className="sites-table">
                {allSites.map((s, key) => {
                  let disable = s?.linkStatus === 'processing' || s?.linkStatus === 'error'
                  return (
                    <tr key={key}>
                    <td>
                      <StatusCell
                        passwordProtected={s.passwordProtected}
                        siteStatus={s?.linkStatus}
                      />
                    </td>
                    <td>
                      <DomainCell
                        passwordProtected={s.passwordProtected}
                        label={getDomain(s)}
                        href={`https://${getDomain(s)}`}
                        directLink={getDirectFileLinkForSite(s)}
                        trial={!!!userProfile.productId}
                        site={s}
                        handlePreviewClick={onPreview}
                        disabled={disable}
                        onUpgrade={() => onUpgrade(PLANS.TINY.id)}
                      />
                    </td>
                    <td>
                      <FeaturedCell
                        onEditPDF={onEditPDF}
                        onEditHTML={() => onEditHTML(getDomain(s), !hasFeature(userProfile.productId, F_EDIT_MODE))}
                        onEditText={() => {onEditText(s)}}
                        directFileLink={getDirectFileLinkForSite(s)}
                        onAnalytics={() => onAnalytics(s)}
                        fileType={s.type}
                        authEditPdf={() => handleEditPdfWithPSWD(s?.password, getDirectFileLinkForSite(s))}
                        siteType={s.siteType}
                        disabled={disable}
                      />
                    </td>

                    <td>{s.lastUpdated || s.created ? moment(s.lastUpdated || s.created).format('DD MMM YY') : '-'}</td>
                    <td className="actions-col">
                      <IconButton
                        className="me-2"
                        src="/assets/icons/upload.png"
                        label="Update"
                        onClick={() => handleUpdateClick(s)}
                      />
                      {!s.linkPreview && (
                        <IconButton
                          className="me-2"
                          src="/assets/icons/copy.png"
                          onClick={() => copyToClipboard(`https://${getDomain(s)}`)}
                          tooltip="Copy link"
                          disabled={disable}
                        />
                      )}
                      <IconButton
                        className="me-2"
                        src="/assets/icons/qr-code.svg"
                        onClick={() => onQRCode(s)}
                        tooltip="View QR Code"
                        disabled={s.linkPreview ? true : disable}
                      />
                      <IconButton
                        className="me-2"
                        src="/assets/icons/thrash.png"
                        tooltip="Delete"
                        onClick={() => onDelete(s)}
                      />
                      <OverflowMenu
                        onEditHTML={() => onEditHTML(getDomain(s), !hasFeature(userProfile.productId, F_EDIT_MODE))}
                        onEditPDF={() => onEditPDF(getDirectFileLinkForSite(s))}
                        onArchive={() => onArchive(s)}
                        onDownload={() => onDownload(s)}
                        onAnalytics={() => onAnalytics(s)}
                        onQRCode={() => onQRCode(s)}
                        onDelete={() => onDelete(s)}
                        onCopy={() => copyToClipboard(`https://${getDomain(s)}`)}
                        onReplaceFile={() => handleUpdateClick(s)}
                        onEmbed={() => onEmbed(s)}
                        link={getDomain(s)}
                        type={s.type}
                        passwordProtected={s.passwordProtected}
                        enableEmailGate={s.enableEmailGate}
                        linkPreview={s.linkPreview}
                        onShare={() => onShare(s)}
                        trial={!!!userProfile.productId}
                        disabled={disable}
                        onUpgrade={() => onUpgrade(PLANS.TINY.id)}
                      />
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>

          <div className="scroll-container mobile-table">
            <table>
              <thead>
                <tr>
                  <th className="column1 table-header">Project</th>
                  <th className="table-header">Actions</th>
                </tr>
              </thead>
              <tbody className="sites-table">
                {allSites.map((s, key) => {
                  const disable = s?.linkStatus === 'processing' || s?.linkStatus === 'error'
                  return (
                    <tr key={key}>
                      <td className="">
                        <DomainCell
                          passwordProtected={s.passwordProtected}
                          label={getDomain(s)}
                          href={`https://${getDomain(s)}`}
                          directLink={getDirectFileLinkForSite(s)}
                          trial={!!!userProfile.productId}
                          site={s}
                          handlePreviewClick={onPreview}
                          disabled={disable}
                          onUpgrade={() => onUpgrade(PLANS.TINY.id)}
                        />
                        <div className="row-content">
                          <StatusCell
                            passwordProtected={s.passwordProtected}
                            domain={getDomain(s)}
                            siteStatus={s?.linkStatus || null}
                            disabled={disable}
                          />
                          <div className="modified">
                            <span>Last modified:</span>{' '}
                            {s.lastUpdated || s.created ? moment(s.lastUpdated || s.created).format('DD MMM YY') : '-'}
                          </div>
                        </div>
                      </td>

                      <td className="actions-col-mobile">
                        <IconButton
                          src="/assets/icons/upload.png"
                          tooltip="Update"
                          onClick={() => handleUpdateClick(s)}
                          disabled={disable}
                        />
                        {!s.linkPreview && (
                          <IconButton
                            src="/assets/icons/copy.png"
                            onClick={() => copyToClipboard(`https://${getDomain(s)}`)}
                            tooltip="Copy link"
                            disabled={disable}
                          />
                        )}
                        <IconButton
                          src="/assets/icons/qr-code.svg"
                          onClick={() => onQRCode(s)}
                          tooltip="View QR Code"
                          disabled={s.linkPreview ? true : disable}
                        />
                        <IconButton src="/assets/icons/thrash.png" tooltip="Delete" onClick={() => onDelete(s)} />
                        <OverflowMenu
                          onEditHTML={() => onEditHTML(getDomain(s), !hasFeature(userProfile.productId, F_EDIT_MODE))}
                          onEditPDF={() => onEditPDF(getDirectFileLinkForSite(s))}
                          onArchive={() => onArchive(s)}
                          onDownload={() => onDownload(s)}
                          onAnalytics={() => onAnalytics(s)}
                          onQRCode={() => onQRCode(s)}
                          onDelete={() => onDelete(s)}
                          onCopy={() => copyToClipboard(`https://${getDomain(s)}`)}
                          onReplaceFile={() => handleUpdateClick(s)}
                          onEmbed={() => onEmbed(s)}
                          link={getDomain(s)}
                          type={s.type}
                          passwordProtected={s.passwordProtected}
                          enableEmailGate={s.enableEmailGate}
                          linkPreview={s.linkPreview}
                          onShare={() => onShare(s)}
                          trial={!!!userProfile.productId}
                          disabled={disable}
                          onUpgrade={() => onUpgrade(PLANS.TINY.id)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="table-empty">
          <div>
            <span className="light-grey">No live projects</span>
          </div>
          <Button className="mt-3" size="lg" variant="outline-primary" onClick={() => onSiteAction(ACTION_CREATE)}>
            Upload
          </Button>
        </div>
      )}
    </div>
  )
}

export default LiveSitesTable
