import React from 'react'
import PlanCard from './components/PlanCard'
import { PLANS } from '../../constants/plans'
import PricingTable from 'components/PricingTable/PricingTable'

import styles from './PricingGrid.module.css'
import { LogoShowcase } from '../../Landing/Home/Home'

export const ALL_FEATURES = [
  {
    label: 'Remove Tiiny Host banner',
    tooltip: 'No Tiiny Host branding on your content',
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Alternate domains',
    tooltip: 'Upload to our exclusive & more reliable .tiiny.co domain',
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Custom domains',
    tooltip: 'Use your website name to publish your content, custom SSL included',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Built-in analytics',
    tooltip: "Automatically track the number of visitors and where they're coming from",
    enabled: [PLANS.TINY.id, PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Edit mode',
    tooltip: 'Easily edit websites, text in PDFs, HTML, CSS & JavaScript files online using our built-in editors',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Password protection',
    tooltip: 'Restrict who can view your content by adding a password',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Capture emails',
    tooltip: 'Capture potential leads by requiring visitors to enter their email address',
    enabled: [PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Disable PDF downloads',
    tooltip: 'Remove the download button from PDF websites',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Custom 404 pages',
    tooltip: 'Configure your own 404 error pages when content not found',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Integrations',
    tooltip: 'e.g. Zapier',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  },
  {
    label: 'Add team members',
    tooltip: 'Share account with up to two more users (upgradeable)',
    enabled: [PLANS.PRO_U.id],
    featured: true
  },
  {
    label: 'Add additional projects',
    tooltip: 'Buy more active projects for your account',
    enabled: [PLANS.PRO.id, PLANS.PRO_U.id]
  }
]

const PricingGrid = ({ className, period, prices, onSubPlanClick, onFreeClick, hideFreeCard, hideTable }) => {
  return (
    <>
      <div className={`${className} ${styles.container} mb-5`}>
        {!hideFreeCard && (
          <PlanCard
            title="Free"
            plan={PLANS.FREE}
            planPrice={prices.freePlan}
            subtitle="Try us out for a quick project or two"
            features={ALL_FEATURES.filter(({ enabled }) => enabled.includes(PLANS.FREE.id))}
            period={period}
            position="start"
            ctaTheme="outline-primary"
            onSubPlanClick={onFreeClick}
          />
        )}
        <PlanCard
          plan={PLANS.TINY}
          planPrice={prices.tinyPlan}
          title="Tiny"
          subtitle="Perfect for a single, professional link"
          features={ALL_FEATURES.filter(({ enabled }) => enabled.includes(PLANS.TINY.id))}
          period={period}
          ctaTheme="blue"
          onSubPlanClick={onSubPlanClick}
        />
        <PlanCard
          plan={PLANS.PRO}
          planPrice={prices.soloPlan}
          title="Solo"
          subtitle="Great for individuals and small sites"
          features={ALL_FEATURES.filter(({ enabled }) => enabled.includes(PLANS.PRO.id))}
          period={period}
          ctaTheme="purple"
          onSubPlanClick={onSubPlanClick}
          popular
        />
        <PlanCard
          plan={PLANS.PRO_U}
          planPrice={prices.proPlan}
          title="Pro"
          subtitle="For freelancers, agencies & organizations"
          features={ALL_FEATURES.filter(({ enabled }) => enabled.includes(PLANS.PRO_U.id))}
          period={period}
          ctaTheme="warning"
          onSubPlanClick={onSubPlanClick}
        />
      </div>
      {!hideTable && (
        <>
          <LogoShowcase title="Do more with your links" className="mt-2 mb-5" />
          <PricingTable plans={PLANS} prices={prices} period={period} featureList={ALL_FEATURES} />
        </>
      )}
    </>
  )
}

export default PricingGrid
