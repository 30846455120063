import React from 'react'
import { isDevSite } from './utils/general'

export const DOMAIN_EXISTS = 'DOMAIN_EXISTS'
export const INVALID_UPDATE_CODE = 'INVALID_UPDATE_CODE'
export const DOMAIN_SUFFIX = '.tiiny.site'
export const NOT_OWNER = 'NOT_OWNER'
export const NO_INDEX_FILE = 'NO_INDEX_FILE'
export const USER_EXISTS = 'USER_EXISTS'
export const USER_TRIAL_EXPIRED = 'USER_TRIAL_EXPIRED'
export const INVALID_EMAIL = 'INVALID_EMAIL'
const USER_UNVERIFIED = 'USER_UNVERIFIED'
const FLAGGED = 'FLAGGED'

export const TLDS = {
  TIINY_SITE: '.tiiny.site',
  TIINY_CO: '.tiiny.co',
  TIINY_CO_UK: '.tiiny.co.uk',
  TIINY_IO: '.tiiny.io'
};

export const DEFAULT_TLDS = Object.values(TLDS)

export const TLD_S3_PATH_CONFIG = {
  [TLDS.TIINY_SITE]: ({ subdomain }) => subdomain,
  [TLDS.TIINY_CO]: ({ subdomain, tld }) => `${subdomain}${tld}`,
  [TLDS.TIINY_CO_UK]: ({ subdomain, tld }) => `${subdomain}${tld}`
}

export const FILE_TYPE = {
  PDF: 'PDF',
  HTML: 'HTML'
}

export const SITE_UPLOAD_TEXT = 'UPLOAD_TEXT'

// Analytics
export const SOURCE_HOMEPAGE = 'SOURCE_HOMEPAGE'
export const SOURCE_LOGIN = 'SOURCE_LOGIN_PAGE'
export const SOURCE_PRICING = 'SOURCE_PRICING_PAGE'
export const SOURCE_LOGGED_IN = 'SOURCE_LOGGED_IN'

export const ERROR_MSGS = {
  [INVALID_EMAIL]: 'Please enter a genuine email address',
  [INVALID_UPDATE_CODE]: 'Invalid update code',
  [NOT_OWNER]: "You don't own this link",
  [NO_INDEX_FILE]: "Your zip doesn't contain an index.html file",
  [DOMAIN_EXISTS]: 'Sorry, that link name is taken',
  [USER_EXISTS]: 'Please login to your account',
  [USER_TRIAL_EXPIRED]: 'Sorry, your trial has expired. Please upgrade.',
  [USER_UNVERIFIED]: "Please verify your account, we've sent you an email",
  [FLAGGED]:
    'We\'re unable to host this type of content <br />on a <b>trial account</b>.<br/><br/><a href="https://tiiny.host/pricing"><b><u>Upgrade your account</u></b></a>'
}

/* eslint-disable */
// prettier-ignore
export const REGEX_FULLDOMAIN = /^([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
// prettier-ignore
export const REGEX_SUBDOMAIN = /^(?!-)[A-Za-z0-9-]*[A-Za-z0-9]-?$/
// prettier-ignore
export const REGEX_DOMAIN = /^[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])*$/;
// prettier-ignore
export const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// prettier-ignore
export const REGEX_USERNAME = /^[a-zA-Z0-9_]{3,20}$/;
// prettier-ignore
export const REGEX_PASSWORD = /^[^:]+$/;
// prettier-ignore
export const REGEX_ERROR_PATH = /^\/.+$/;

export const DISALLOWED_EMAILS = ['hi2.in', 'mailinator.com']
/* eslint-disable */

export const MAX_FILE_SIZE = 25 // link preview max
export const MAX_PDF_SIZE = 25 // link preview max

export const MAX_FREE_FILE_SIZE = 3
export const MAX_FREE_PDF_SIZE = 0.5

const getBaseURL = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8000'
  } else if (isDevSite()) {
    return 'https://api.tiiny.co.uk'
  } else {
    return 'https://api.tiiny.host'
  }
}

export const API_ROOT = getBaseURL()

export const reCAPTCHA_CLIENT = '6LeMIfQUAAAAAGblGGmutcrS1gaG3YMyRPzkG5N7'

const getStripeKey = () => {
  if (isDevSite()) {
    return 'pk_test_51GyQOLKnX1nnNv6ISZ7YCm5nBySiWLHHcFIir2ALdrrARBkHnSwZe2CTbadw9pWLEBbLVncQOAsXh56RK8YNMRsD00QdWbapEb'
  } else {
    return 'pk_live_51GyQOLKnX1nnNv6I5F3a2LwxUfNq7kDmV4oNDqpXk1LGdgaKaiOelZsUt8I7SYqffFSkBeYkyMNU9kWBAo7vFqtx00Rcdt5Fcu'
  }
}

export const STRIPE_KEY = getStripeKey()

export const ARCHIVE_ENDPOINT = 'https://s3-eu-west-1.amazonaws.com/archive.tiiny.host'

export const LIFETIME_PRICE = 79

export const ENTRI_APP_ID = isDevSite() ? 'tiiny.host_dev' : 'tiiny.host'

export const PSPDFKIT_LICENSE_KEY =
  'Tk6emxoHPFuLnQnuOKXgYMuE_zUqdg24KHBbegiVIY7kNddDqd5miEJDk4wcW6NaHq1CV3AYgtrlRl6_Rl1_4-tMaB09CZjEqu73Y_os0tL2N_5HL5J7tKbdYshmNyx6nguBJyk8l02_CnZ8Mf0iBDp3TTfdb7Q-Fz5zbUAT7BgFrHBjrAA-bsrcoAifaqVCLKrEL1eNJlIyN1nMDA_ZL9ouBQFeF-Ykvl6oPbUUPyso2mrNZFlugCjFHR_f0ZWdq_rADyrKWIfuRPEHxrVKU6o6T2cg1fO2fGTukWq3mxsUZVCd8ux5Q5I16HgjJXcDFf7FPLpYNNMTrK8AS0VKjKCo8CTcwj3Bs7QIGOmVKzbV55-aj_X3s2I52huRaRJtri_xb6VEq2mbFysnpFsFM-3vtncXrhbRA6IY4Uk4RakzzErmojSL1pqwVv6el78mGP1GMW8S4g3NJspd5HtdAWxZb82BZzFka1vvoFKZez0ASXKnbUlXAVvhGNUs3KMfrBGYOqonFldI7QmkSFD3vtyLAcHZAXSa8p7HuvDauGk='

export const DROPZONE_ACCEPTED_FILES = 'text/html, application/zip, application/x-zip-compressed, multipart/x-zip, application/pdf, .pdf, .php, text/php'