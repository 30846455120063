import React from 'react';
import {Button} from "react-bootstrap";

const Email = () => (<div>
    <h1 className="bold">Get in touch</h1>
    <p className="answer">Get in touch with us about anything. Ideas, suggestions or complaints.</p>
    <div className="d-md-flex gap-5">
      <div className="box-100 p-5 mt-4 border-grey">
        <h2>Helpdesk</h2>
        <p>The quickest way to find your answer.</p>
        <Button variant="purple" href="https://helpdesk.tiiny.host">Search helpdesk</Button>
      </div>
      <div className="box-100 p-5 mt-4 border-grey">
        <h2>Chat</h2>
        <p>The quickest way to reach us for specific questions.</p>
        <Button variant="purple" onClick={() => window.$crisp.push(['do', 'chat:open'])}>Chat with us</Button>
      </div>
      <div className="box-100 p-5 mt-4 border-grey">
        <h2>Email</h2>
        <p>We'll get back to you within 24 hours.</p>
        <Button variant="purple" href="mailto:support@tiiny.host">Email us</Button>
      </div>
    </div>
</div>);

export default Email;
