import React, {useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {withRouter} from "react-router";
import * as Sentry from "@sentry/react";
import {ErrorBox} from "../components/ErrorBox/ErrorBox";
import {configureProAccount, configureTrialAccount, updateMetadata} from "../services/manage";
import {trackNewUser, trackOnboardingSurvey} from "../utils/analytics";
import {getErrorMessage} from "../utils/general";
import OnBoardingSurvey from "../components/OnBoardingSurvey/OnBoardingSurvey";

import './ProWelcome.css';
import './fireworks.css';

const STAGE_PRO_HELLO = 0;
const STAGE_ONBOARDING_SURVEY = 1;

const STATUS_DEFAULT = { loading: false, success: false, error: false }

const ProWelcome = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const skipForm = urlParams.get('skipq') === 'true'
  const trial = urlParams.get('trial') === 'true';
  const accountConfigured = urlParams.get('accountconfigured') === 'true';

  const [stage, setStage] = useState(trial ? 1 : 0)
  const [status, setStatus] = useState(STATUS_DEFAULT);
  const [errorMessage, setErrorMessage] = useState();

  if (stage === STAGE_PRO_HELLO) {
    document.body.style.overflow = "none";
  }

  const onProHello = () => {
    document.body.style.overflow = "scroll";

    if (!skipForm) {
      setStage(STAGE_ONBOARDING_SURVEY)
    } else {
      onContinue();
    }
  }

  const onContinue = (metadata = {}) => {
    setStatus({
      ...STATUS_DEFAULT,
      loading: true
    })

    if (localStorage?.getItem('landing-page')) {
      metadata.landingPage = localStorage.getItem('landing-page');
      localStorage.removeItem('landing-page');
    }

    // if account already configured, only update metadata
    if (accountConfigured) {
      updateMetadata({metadata})
        .then(() => history.push('/manage'))
        .catch(() => {
          setStatus(STATUS_DEFAULT);
        })
      return;
    }

    // else configure user & update metadata
    const customerId = urlParams.get('id');
    const email = decodeURI(urlParams.get('email'));
    const subscriptionId = urlParams.get('sid');
    const paymentGateway = urlParams.get('payment');
    const percentOff = urlParams.get('percentOff');

    const service = trial ? configureTrialAccount : configureProAccount;

    service({
      customerId,
      email,
      metadata,
      subscriptionId,
      percentOff,
      paymentGateway
    })
      .then((res) => {
        if (!skipForm) {
          trackOnboardingSurvey(
            email,
            metadata?.discovery,
            metadata?.job,
            metadata?.role,
            metadata?.useCase
          )
          trackNewUser(trial ? 'free' : 'paid', email)
        }
        localStorage.setItem("token", res.data.jwt);
        history.push('/manage?first=true')
      })
      .catch(err => {
        setStatus({
          ...STATUS_DEFAULT,
          error: true,
        });
        setErrorMessage(getErrorMessage(err));
        Sentry.captureException(new Error('UNKNOWN_ONBOARDING_ERROR'), {
          tags: {
            section: "homepage",
          },
        });
      })
  };

  return <div>
    {stage === STAGE_PRO_HELLO && !status.error && <div className="pyro">
      <div className="before" />
      <div className="after" />
      <div className="box-100 pro-hello p-5 w-75">
        <div className="m-auto">
          <img
            src="./assets/logo.png"
            width="120"
          />
          <h1 className="pro-hello-text">Welcome to your subscription</h1>
        </div>
        <Button
          disabled={status.loading}
          className="ms-auto me-auto mt-3"
          size="lg"
          onClick={onProHello}
        >
          {status.loading ? <Spinner animation="border" size="sm"/> : 'Continue'}
        </Button>
      </div>
    </div>}
    {stage === STAGE_ONBOARDING_SURVEY && !status.error &&
    <div className="container-pro-welcome">
      <div className="pro-welcome-header">
        <a href="/">
          <img
            className="float-left ms-3"
            src="./assets/logo.png"
            width="140"
          />
        </a>
        <h4 className="pro-welcome-text divider float-left ms-2">
          Welcome{!trial && !skipForm && ' to your subscription'}
        </h4>
      </div>
      <div className="box-100 text-start p-4">
        {!status.error &&
          <>
            {!skipForm && <OnBoardingSurvey onContinue={onContinue} status={status} />}
          </>}
      </div>
    </div>}
    {status.error && <div style={{ marginTop: '300px' }}>
      <ErrorBox
        className={status.error ? 'd-block' : 'd-none'}
        message={errorMessage || `Unable to configure your account, <br />please try logging in again`}
        onClick={() => window.location = '/login'}
      />
    </div>}
  </div>
};

export default withRouter(ProWelcome);
