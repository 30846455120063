import React, { useState } from 'react';
import styles from './Embed.module.css';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { copyToClipboard } from '../../utils/general';

const EmbedModal = ({ onHide, show, domain }) => {
  const [width, setWidth] = useState('500');
  const [height, setHeight] = useState('500');
  const [copied, setCopied] = useState(false);
  const iframeCode = `<iframe src="https://${
    domain?.subdomain ? domain.subdomain : ''
  }" width="${width}" height="${height}" allowfullscreen></iframe>`;

  const onClose = () => {
    setCopied(false);
    onHide();
  };
  const onCopy = () => {
    copyToClipboard(iframeCode);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className={styles.container}>
        <div>
          <h2 className={styles.header}>Copy Embed Code</h2>
          <span className={styles.subHeader}>Copy and paste the HTML below into your website code.</span>
        </div>
        <div className={styles.code}>
          <div className={styles.copyStatus}>
            {copied && (
              <div>
                <span>Copied! </span>
                <img className="puff-in-center ms-auto" src="/assets/icons/check.svg" height="16px" alt="success" />
              </div>
            )}
          </div>
          <textarea className={styles.textarea} rows="5" cols="50" readOnly value={iframeCode} />
        </div>
        <div className={styles.footer}>
          <div className={styles.content}>
            <div className={styles.dimensionBox}>
              <div className={styles.dimension}>
                <span className={styles.dimensionTitle}>Width</span>
                <input
                  className={styles.dimensionInput}
                  type="text"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
              </div>
              <div className={styles.dimension}>
                <span className={styles.dimensionTitle}>Height</span>
                <input
                  className={styles.dimensionInput}
                  type="text"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            </div>
          </div>
          <Button className="float-right" variant="outline-primary" onClick={onCopy}>
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EmbedModal;
