import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const CanonicalLink = ({ history }) => {

  const getBasePath = (pathname) => {
    // Remove trailing slash and anything after it
    return pathname.replace(/\/$/, '');
  };

  // Construct canonical URL based on the current route
  const constructCanonicalUrl = (pathname) => {
    return `${window.location.origin}${getBasePath(pathname)}`;
  };

  const [canonicalUrl, setCanonicalUrl] = React.useState(constructCanonicalUrl(history.location.pathname));

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const newCanonicalUrl = constructCanonicalUrl(location.pathname);
      setCanonicalUrl(newCanonicalUrl);
    });

    // Clean up the listener on component unmount
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalLink;
