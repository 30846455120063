import React from 'react';
import {Button} from "react-bootstrap";
import { API_ROOT } from '../../constants';

const PROVIDERS = ['google', 'github', 'microsoft', 'yahoo'];

const Login = ({ provider, onClick, disabled }) => (
  <Button
    className="btn social-button border"
    variant="outline-primary"
    type="submit"
    disabled={disabled}
    {...(onClick && { onClick: () => onClick(provider) })}
    {...(!onClick && { href: `${API_ROOT}/oauth/${provider}` })}
  >
    <img className="m-auto" src={`/assets/icons/${provider}.svg`} alt={`${provider} logo`} />
  </Button>
);

const SocialLogin = ({ className = '', onClick, disabled }) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      {PROVIDERS.map((p) => (
        <Login key={p} provider={p} onClick={onClick} disabled={disabled} />
      ))}
    </div>
  );
}

export default SocialLogin;
