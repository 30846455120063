import React from 'react';
import moment from "moment";
import LinkButton from "../LinkButton";
import {ARCHIVE_ENDPOINT} from "../../constants";
import { getS3Path, getSubdomainAndTLD } from 'utils/general';

const getArchiveLabel = (siteRecord) => siteRecord.subdomain

const getArchiveDownloadLink = (archiveRecord) => {
  const customDomain = archiveRecord.isCustomDomain
  const {subdomain, tld} = getSubdomainAndTLD({site: archiveRecord})
  const s3Path = getS3Path({site: archiveRecord, isCustomDomain: customDomain})
  if (customDomain) {
    return `${ARCHIVE_ENDPOINT}/${archiveRecord.id}%23${s3Path}/__archive__/backup.zip`
  } else {
    return `${ARCHIVE_ENDPOINT}/${archiveRecord.id}%23${s3Path}/__archive__/backup.zip`
  }
}

const ArchiveTable = ({sites, onDeleteArchive}) => <div className="box-100">
  {
    sites.length > 0 ?
      <table>
        <thead>
        <tr>
          <th/>
          <th className="font-small grey">LINK</th>
          <th className="font-small grey">CREATED</th>
          <th className="font-small grey">ACTIONS</th>
        </tr>
        </thead>
        <tbody className='sites-table'>
        {
          sites.map((s, key) => <tr key={key}>
            <td>
              <img className="opacity-25 ps-2" src="/assets/icons/circle.svg" height="14px" alt="archive" />
            </td>
            <td>
              {getArchiveLabel(s)}
            </td>
            <td>{moment(s.created).format('DD MMM YY')}</td>
            <td>
              <LinkButton
                label="DELETE"
                onClick={() => onDeleteArchive(s)}
              />
              <a
                href={getArchiveDownloadLink(s)}
                target="_blank"
              >
                <LinkButton
                  label="DOWNLOAD"
                  onClick={() => onDeleteArchive(s)}
                />
              </a>
            </td>
          </tr>)
        }
        </tbody>
      </table>
      :
      <div className="table-empty">
        <div>
          <span className="light-grey bold">No archived sites</span>
        </div>
        <div
          className="mt-1 text-sm light-grey"
        >
          Archived sites will appear here.
        </div>
      </div>
  }
</div>;

export default ArchiveTable
