import React from 'react';

import BorderButton from "../BorderButton";

import './UpgradeCard.css'

export const UPGRADES = [
  {
    desc: 'Upload up to 1GB with Pro',
    cta: 'Upload larger files',
    colorScheme: 'purple'
  },
  {
    desc: 'Connect your custom domain with Pro',
    cta: 'Connect',
    colorScheme: 'green'
  },
  {
    desc: 'Password protect your link with Pro',
    cta: 'Add password',
    colorScheme: 'yellow'
  },
]

const UpgradeCard = ({ desc, cta, onClick, colourScheme = 'purple', className = '' }) => {
  return <div
    className={`upgrade-card text-start p-4 cursor-pointer upgrade-card-underlay upgrade-bg-${colourScheme} ${className}`}
    onClick={onClick}
  >
    <div className="bold font-22 white w-75 line-height-s">
      {desc}
    </div>
    <BorderButton className="mt-3" label={cta} />
  </div>
}

export default UpgradeCard;
