import styles from '../CustomDomainModal.module.css'
import { useState } from 'react';
import IconButton from '../../IconButton'
import { copyToClipboard } from '../../../utils/general'

export const getFirstPart = (str) => {
    const dotIndex = str.indexOf('.')
    if (dotIndex > -1) {
      return str.substring(0, dotIndex)
    } else {
      return str
    }
  }
  

export const CopyIcon = ({ text }) => {
    const [copied, setCopied] = useState(false)
  
    const handleCopy = () => {
      copyToClipboard(text)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
    return (
      <div className={styles.copy} onClick={handleCopy}>
        <IconButton src="/assets/icons/copy.png" tooltip={copied ? 'Copied!' : 'Copy'} className={styles.copyIcon} />
      </div>
    )
  }