export const SHOW_UPGRADE_CARD_MODAL = 'SHOW_UPGRADE_CARD_MODAL';
export const showUpgradeCardModal = (payload) => ({
  type: SHOW_UPGRADE_CARD_MODAL,
  payload
});

export const HIDE_UPGRADE_CARD_MODAL = 'HIDE_UPGRADE_CARD_MODAL';
export const hideUpgradeCardModal = (payload) => ({
  type: HIDE_UPGRADE_CARD_MODAL,
  payload
});

export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL';
export const setShowPaymentModal = (payload) => ({
  type: SHOW_PAYMENT_MODAL,
  payload
})