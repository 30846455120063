import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const CurrencyDropdown = ({ selectedCurrency, onCurrencySelected, availableCurrencies }) => {
  return (
    <DropdownButton className="text-start" title={selectedCurrency} size="sm" variant="outline-secondary">
        {availableCurrencies.map((currency) => (
          <Dropdown.Item
            key={currency.symbol}
            eventKey={currency.symbol}
            onClick={() => onCurrencySelected(currency.symbol)}
          >
            {currency.symbol} ({currency.name})
          </Dropdown.Item>
        ))}
    </DropdownButton>
  );
};

export default CurrencyDropdown;
