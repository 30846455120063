import React, { useState } from 'react';
import styles from './FeaturesBox.module.css';

const data = [
  {
    title: 'Capture emails',
    description: 'Capture visitor email addresses for lead generation',
    icon: 'assets/img/sharing/mail.svg',
    slug: 'email_capture',
    image: 'assets/img/sharing/capture-emails.png',
    learnMore: 'https://youtu.be/tlaDPsCF4Wo',
    id: 6
  },
  {
    title: 'Make edits',
    description: 'Easily edit HTML or PDF text in seconds & re-publish your work',
    icon: 'assets/img/sharing/edit.svg',
    slug: 'edit_mode',
    image: 'assets/img/sharing/edit-graphic.png',
    id: 3
  },
  {
    title: 'Password protect',
    description: 'Keep your content private with a customized password login',
    icon: 'assets/img/sharing/lock.svg',
    slug: 'password_protect',
    image: 'assets/img/sharing/lock-graphic.png',
    learnMore: 'https://youtu.be/rod_2W3GAGQ',
    id: 4
  },
  {
    title: 'Generate QR Codes',
    description: 'Autogenerated QR code for every link for easy sharing',
    icon: 'assets/img/sharing/qr-code.svg',
    slug: 'qr-code',
    image: 'assets/img/sharing/qr-code-graphic.png',
    id: 5
  },
];

const FeaturesBox = ({ title, className }) => {
  const [activeTab, setActiveTab] = useState('email_capture');
  const [selectedTab, setSelectedTab] = useState(data[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedTab(data.find((item) => item.slug === tab));
  };

  return (
    <div className={`${styles.page} ${className}`}>
      <div className={styles.header}>
        <p className={styles.headerText}>
          {title || <>The power of the web, <br/><span className={styles.colorText}>made user-friendly</span></>}
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.features}>
          {data.map((item, index) => {
            return (
              <div
                className={`${styles.feature} ${styles.transition} ${activeTab === item.slug && styles.active}`}
                key={index}
                onClick={() => handleTabClick(item.slug)}
              >
                <img
                  src={item.icon}
                  alt={item.slug}
                  className={activeTab === item.slug ? styles.iconActive : styles.imgFill}
                  height="40"
                />
                <div className={styles.featureText}>
                  <span className={activeTab === item.slug ? styles.activeTitle : styles.title}>{item.title}</span>
                  {activeTab === item.slug && <>
                    <p className={styles.description}>{item.description}</p>
                    {item.learnMore &&
                      <a className="text-black" href={item.learnMore} target="_blank">
                        Learn more &rarr;
                      </a>}
                  </>}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.featureImageCon}>
          <img src={selectedTab.image} alt="" width={400} />
        </div>
      </div>
    </div>
  );
};

export default FeaturesBox;
