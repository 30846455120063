import React from 'react';
import ProCard from "../ProCard";
import {PLANS} from "../../constants/plans";
import CustomTooltip from "../CustomTooltip";

export const ToolTip = ({ text }) => <CustomTooltip label={text}>
  <img className="ms-2 opacity-50 " src="/assets/icons/info-grey.svg" height="15"/>
</CustomTooltip>

export const GreyBorderButton = ({ onClick, children, className = '' }) =>
  <div
    className={`grey-border-button round-8 border-grey ms-auto me-auto cursor-pointer p-1 ${className}`}
    onClick={onClick}
  >
    {children}
  </div>

const CustomProCard = ({ features }) => <div
  className="pro-box ms-md-4 mt-4 mt-md-0 flip-in-ver-left bg-white"
  onClick={() => window.$crisp.push(['do', 'chat:open'])}
  style={{ border: 'solid 1px #e9e9e9', background: 'none' }}
>
  <h5>Custom plan</h5>
  {features.map((feat, i) =>
    <div key={i} className="plan-feature">
      <img height="20px" width="20px" alt="check" src="/check.png"/> {feat}
    </div>)}
  <GreyBorderButton className="mt-3">
    Contact us
  </GreyBorderButton>
</div>

const PlanCards = ({onProClick, onFreeClick, pricingMode, hideFreeCard, planSet = PLANS}) => <>
  <div className="pro-plans-container">
    {!hideFreeCard && <ProCard
      className="me-md-4"
      label="Free Trial"
      price={0}
      features={[
        <span>1 live site</span>,
        <span>Upload up to <b>3mb</b> files</span>,
        'Tiiny Host branding'
      ]}
      buttonLabel="Upload"
      onClick={onFreeClick}
    />}
    {planSet.PRO && <ProCard
      className="me-md-4 mt-4 mt-md-0 tr-pro-card tr-pro-plan flip-in-ver-left"
      label={planSet.PRO.label}
      price={planSet.PRO.prices[pricingMode].value}
      ccy={planSet.PRO.prices[pricingMode].ccy}
      period={pricingMode.toLowerCase()}
      description="Great for individuals and small sites"
      features={[
        <>
          <span>Up to <b>5 live</b> sites</span>
          <ToolTip text="Host up to 5 live sites at once" />
        </>,
        <>
          <span>Upload up to <b>75mb</b> files</span>
          <ToolTip text="Great for small files, upload up to 75mb per file" />
        </>,
        <>
          <span>Link your <b>custom domains</b></span>
          <ToolTip text="Use your own domain name to publish content, custom SSL included" />
        </>,
        <>
          <span><b>Analytics</b></span>
          <ToolTip text="Automagically view the number of visitors and where they're coming from" />
        </>,
        <>
          <span><b>Password protection</b></span>
          <ToolTip text="Restrict who can view your content by adding a password" />
        </>,
        <>
          <span>Fast global hosting (CDN)</span>
          <ToolTip text="Sites hosted on a custom domain published through a Content Delivery Network" />
        </>,
        <>
          <span>No Tiiny Host branding</span>
          <ToolTip text="Remove our branding from your published content" />
        </>,
        <>
          <span>Amazing support</span>
          <ToolTip text="Friendly, personalized & reliable support, no bots" />
        </>,
      ]}
      onClick={() => onProClick('PRO')}
      billableMonthly
    />}
      {planSet.PRO_U && <ProCard
        className="mb-auto mt-4 mt-md-0 tr-pro-card tr-pro-plus-plan flip-in-ver-left"
        label={planSet.PRO_U.label}
        price={planSet.PRO_U.prices[pricingMode].value}
        ccy={planSet.PRO_U.prices[pricingMode].ccy}
        period={pricingMode.toLowerCase()}
        description="For freelancers, agencies & organizations"
        features={[
          <>
            <span>Up to <b>12 live</b> sites</span>
            <ToolTip text="Host up to 12 live sites at once" />
          </>,
          <>
            <span>Upload <b>over 75mb</b> files</span>
            <ToolTip text="Great for large files, upload up to 1GB per file" />
          </>,
          <>
            <span>Link your <b>custom domains</b></span>
            <ToolTip text="Use your own domain name to publish content, custom SSL included" />
          </>,
          <>
            <span><b>Analytics</b></span>
            <ToolTip text="Automagically view the number of visitors and where they're coming from" />
          </>,
          <>
            <span><b>Password protection</b></span>
            <ToolTip text="Restrict who can view your content by adding a password" />
          </>,
          <>
            <span><b>Capture emails</b></span>
            <ToolTip text="Capture potential leads by requiring visitors to enter their email address" />
          </>,
          <>
            <span>Fast global hosting (CDN)</span>
            <ToolTip text="Sites hosted on a custom domain published through a Content Delivery Network" />
          </>,
          <>
            <span>No Tiiny Host branding</span>
            <ToolTip text="Remove our branding from your published content" />
          </>,
          <>
            <span>Amazing support</span>
            <ToolTip text="Friendly, personalized & reliable support, no bots" />
          </>,
          <>
            <span><b>Add additional users</b></span>
            <ToolTip text="Share account with multiple users (additional upgrade)" />
          </>,
          <>
            <span><b>Edit files online</b></span>
            <ToolTip text="Quickly edit HTML, CSS & JavaScript files online using our built-in code editor" />
          </>,
        ]}
        onClick={() => onProClick('PRO_U')}
        billableMonthly
        mostPopular
      />}
      {!planSet.PRO_U && <CustomProCard
        features={[
          <span><b>All Pro features</b></span>,
          <span><b>Custom number</b> of live sites</span>,
          <span>Upload up to <b>custom mb</b> files</span>,
        ]}
      />}
  </div>
</>

export default PlanCards;