import React, { memo } from 'react';
import DropzoneWrapper from './component/DropzoneWrapper';

import { useSelector } from 'react-redux';

const DragWrapper = memo(({ children, trialAccount, siteLimit, liveSites }) => {
  const updateModalOpen = useSelector((state) => state.manage.displayModal);

  return (
    <div>
      <DropzoneWrapper
        updateModalOpen={updateModalOpen}
        trialAccount={trialAccount}
        siteLimit={siteLimit}
        liveSites={liveSites}
      >
        {children}
      </DropzoneWrapper>
    </div>
  );
});

export default DragWrapper;
