import React, {useState} from 'react';
import {Modal} from "react-bootstrap"
import {connect} from "react-redux";
import Details from "./components/Details";
import Cancel from "./components/Cancel";
import BorderButton from "../BorderButton/index.b";
import { showUpgradeModal } from "../../Manage/actions";

import './ManageAcccountModal.css';

const STAGE_DETAILS = 'DETAILS';
const STAGE_CANCEL = 'CANCEL';

const ManageAccountModal = ({
  customerId = '',
  show,
  onHide,
  cardType,
  productId,
  lastFour,
  status,
  amount,
  interval,
  nextBilling,
  apiKey,
  email,
  currency = '$',
  cancelUrl,
  updateUrl,
  onUpgrade,
  dispatch,
  liveSites
}) => {
  const [stage, setStage] = useState(STAGE_DETAILS)
  const stripeCustomer = `${customerId}`.indexOf('cus_') !== -1;
  const trialAccount = !productId;

  const onCancel = () => {
    setStage(STAGE_CANCEL)
  }

  const handleOnHide = () => {
    setStage(STAGE_DETAILS);
    onHide();
  }

  return (
    <Modal show={show} onHide={handleOnHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="p-3">
        {stage === STAGE_DETAILS && (
          <>
            <Details
              nextBilling={nextBilling}
              amount={amount}
              interval={interval}
              cardType={cardType}
              productId={productId}
              lastFour={lastFour}
              status={status}
              apiKey={apiKey}
              email={email}
              currency={currency}
              stripeCustomer={stripeCustomer}
              cancelUrl={cancelUrl}
              updateUrl={updateUrl}
              onCancel={onCancel}
              onUpgrade={onUpgrade}
            />
            {!trialAccount && (
              <div style={{ opacity: 0.4, fontSize: '14px', float: 'right' }}>
                {`Secured with `}
                {stripeCustomer ? (
                  <a href="https://stripe.com" target="_blank">
                    <img src="/assets/icons/stripe.svg" height="30" />
                  </a>
                ) : (
                  <a href="https://paddle.com" target="_blank">
                    <img src="/assets/icons/paddle.svg" height="20" />
                  </a>
                )}
              </div>
            )}
            { trialAccount && (
                <BorderButton
                className="tr-header-upgrade-btn float-right"
                onClick={() => dispatch(showUpgradeModal(true))}
                label="Upgrade for more"
              />
            )}
          </>
        )}
        {stage === STAGE_CANCEL && (
          <Cancel stripeCustomer={stripeCustomer} onHide={handleOnHide} cancelUrl={cancelUrl} sites={liveSites}
          onUpgrade={onUpgrade}/>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({manage}) => ({
  ...manage.userProfile,
  liveSites: manage.liveSites,
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccountModal);
