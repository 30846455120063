import {
  FILE_TYPE_HTML,
  FILE_TYPE_PDF,
  FILE_TYPE_ZIP,
  HTML_FILE,
  PDF_FILE,
  ZIP_FILE,
  PHP_FILE,
  FILE_TYPE_PHP
} from 'components/CreateUpdateSiteModal/interface'
import JSZip from 'jszip'
import { isHtml } from './general'

const metadataFilesPath = ['__MACOSX', '.DS_Store', 'Thumbs.db', '/._', '.git']

export const filterPossibleHtmlFilesIndex = (htmlFiles) => {
  if (htmlFiles.length === 0) {
    return []
  }

  const directories = {}
  let foundHtmlFiles = []
  let foundIndexHtmlFiles = []

  /** Sort htmlFiles (depending on folder "staging"),
   * e.g : ["folder/folder_1/index.html", "folder/folder_2/index.html" ] will convert into  -
   * {'2': ["folder/folder_1/index.html", "folder/folder_2/index.html" ]} -
   * '2' is the number of folder to reach the file */
  htmlFiles.forEach((htmlFile) => {
    const filePathLength = htmlFile.split('/').length - 1

    if (!directories[filePathLength]) {
      directories[filePathLength] = []
    }
    directories[filePathLength].push(htmlFile)
  })

  const depths = Object.keys(directories)
  let lowestDepth = depths[0]

  depths.forEach((depth) => {
    if (lowestDepth > depth) {
      lowestDepth = depth
    }
  })

  foundHtmlFiles = directories[lowestDepth]
  foundHtmlFiles.map((foundHtmlFile) => {
    const fileName = foundHtmlFile.split('/').pop()

    if (fileName === 'index.html' || fileName === 'index.htm') {
      foundIndexHtmlFiles.push(foundHtmlFile)
    }
  })

  return foundIndexHtmlFiles.length > 0 ? foundIndexHtmlFiles : foundHtmlFiles
}

export const findHtmlFilesInZipFile = async (file) => {
  let htmlFiles = []
  let allHtmlFiles = []
  let rootDirectoryFound = false

  try {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    await new Promise((resolve, reject) => {
      reader.onload = resolve
      reader.onerror = reject
    })

    const content = reader.result
    const zip = await JSZip.loadAsync(content)

    zip.forEach((path) => {
      if (
        (path.endsWith('.html') || path.endsWith('.htm')) &&
        !metadataFilesPath.some((metadataFilePath) => path.includes(metadataFilePath))
      ) {
        allHtmlFiles.push(path)

        const index = path.indexOf('/')

        if (index === -1) {
          rootDirectoryFound = true

          htmlFiles.push(path)
        } else {
          if (!rootDirectoryFound) {
            const secondPath = path.substring(index + 1)
            const secondIndex = secondPath.indexOf('/')

            if (secondIndex === -1) {
              htmlFiles.push(path.substring(index + 1))
            }
          }
        }
      }
    })

    if (htmlFiles.length === 0 && allHtmlFiles.length !== 0) {
      const possibleHtmlIndexFiles = filterPossibleHtmlFilesIndex(allHtmlFiles)
      htmlFiles.push(...possibleHtmlIndexFiles)
    }
  } catch (error) {
    console.error(error)
  }

  return htmlFiles
}

export const getZipRootDirectory = (unzippedFiles) => {
  const index = unzippedFiles[0].path.indexOf('/')

  if (unzippedFiles[0].type === 'Directory' && unzippedFiles[0].path.substring(index + 1) === '') {
    return unzippedFiles[0].path
  }
  return ''
}

export const isIndexFile = (htmlFiles) => {
  let indexFound = 0

  if (htmlFiles.length > 1) {
    htmlFiles.map((htmlFile) => {
      const fileName = htmlFile.split('/').pop()

      if (fileName === 'index.html' || fileName === 'index.htm') {
        indexFound++
      }
    })

    return indexFound === 1 ? true : false
  }

  return true
}

export const getFileType = (fileName) => {
  if (fileName?.endsWith('.pdf')) {
    return PDF_FILE
  } else if (fileName?.endsWith('.zip')) {
    return ZIP_FILE
  } else if (fileName?.endsWith('.html')) {
    return HTML_FILE
  } else if (fileName?.endsWith('.php')) {
    return PHP_FILE
  } else {
    return null
  }
}

export const getPhpFiles = async (zipFile) => {
  const zip = new JSZip()
  const phpFiles = []
  const htmlFiles = []
  const metadataFilesPath = ['__MACOSX', '.DS_Store', 'Thumbs.db', '/._']

  try {
    const zipData = await zip.loadAsync(zipFile)

    zipData.forEach((relativePath, zipEntry) => {
      if (
        relativePath.endsWith('.php') &&
        !metadataFilesPath.some((metadataPath) => relativePath.includes(metadataPath))
      ) {
        phpFiles.push(relativePath)
      }
      if (relativePath.endsWith('.html') || relativePath.endsWith('.htm')) {
        htmlFiles.push(relativePath)
      }
    })

    const htmlFilesInSameDirectoryWithPhp = []

    // Create a set of directories containing PHP files
    const directoriesWithPhpFiles = new Set()
    phpFiles.forEach((file) => {
      const directory = file.substring(0, file.lastIndexOf('/'))
      directoriesWithPhpFiles.add(directory)
    })

    // Collect HTML files that are in the same directories as PHP files
    htmlFiles.forEach((file) => {
      const directory = file.substring(0, file.lastIndexOf('/'))
      if (directoriesWithPhpFiles.has(directory)) {
        htmlFilesInSameDirectoryWithPhp.push(file)
      }
    })

    // Combine PHP files and HTML files in the same directories
    const combinedFiles = [...phpFiles, ...htmlFilesInSameDirectoryWithPhp]

    return {
      containsPhpFile: phpFiles.length > 0,
      phpFiles: combinedFiles
    }
  } catch (error) {
    console.error('Error loading zip file:', error)
    return {
      containsPhpFile: false,
      phpFiles: []
    }
  }
}

export const getFileContentType = (fileName) => {
  if (fileName?.endsWith('.pdf')) {
    return FILE_TYPE_PDF
  } else if (fileName?.endsWith('.zip')) {
    return FILE_TYPE_ZIP
  } else if (fileName?.endsWith('.html')) {
    return FILE_TYPE_HTML
  } else if (fileName?.endsWith('php')) {
    return FILE_TYPE_PHP
  } else {
    return null
  }
}

export const isHtmlFileExisting = (files) => {
  let isHtmlFileFound = false
  files.map((file) => {
    if (getFileType(file?.name) === HTML_FILE) {
      isHtmlFileFound = true
    }
  })
  return isHtmlFileFound
}

export const isIndexFileExisting = (files) => {
  if (isHtmlFileExisting(files)) {
    let isIndexFileFound = false

    files.map((file) => {
      if (file?.name === 'index.html') {
        isIndexFileFound = true
      }
    })
    return isIndexFileFound
  }

  return true
}

export const sortAllHtmlFiles = (files) => {
  return files.map((file) => {
    if (isHtml(file)) {
      return file.path
    }
  })
}

export const getSafeFileName = (fileName) => {
  if (fileName) {
    const index = fileName.lastIndexOf('.')
    const name = fileName.substring(0, index)
    const ext = fileName.substring(index + 1)

    return `${name.replace(/[-\s.]+/g, '-').replace(/[?>~!@%()"{}&#!<>`+]+/g, '')}.${ext}`
  }
}

export const getTrimmedZipFileName = (fileName) => {
  const index = fileName.lastIndexOf('.')
  const name = fileName.substring(0, index)

  return name.indexOf(' ') === -1 ? name : `${name.substring(0, name.indexOf(' '))}`
}
