import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ creationTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(creationTime));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(creationTime));
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [creationTime]);

  return (
    <div>
      Available for {timeRemaining.minutes} mins and {timeRemaining.seconds} secs
    </div>
  );
};

const calculateTimeRemaining = (creationTime) => {
  const currentTime = new Date();
  const creationDate = new Date(creationTime);
  const expirationDate = new Date(creationDate.getTime() + 60 * 60 * 1000);
  const timeDifference = expirationDate.getTime() - currentTime.getTime();

  if (timeDifference <= 0) {
    // Timer has expired
    return { minutes: 0, seconds: 0 };
  }

  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return { minutes, seconds };
};

export default CountdownTimer;
