import SuccessBox from 'components/CreateUpdateSiteModal/components/SuccessBox'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ShareModal = ({ show, onClose, userProfile, site, files, onReset }) => {
  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <SuccessBox userProfile={userProfile} site={site} files={files} onClose={onClose} onReset={onReset} />
    </Modal>
  )
}

export default ShareModal
