import {Button, Modal, Spinner} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getSiteAnalytics } from "../../services/manage";
import { ErrorBox } from "../ErrorBox/ErrorBox";
import { useHistory } from 'react-router-dom'

import styles from './AnalyticsModal.module.css';
import './Analytics.css';

const AnalyticsModal = ({ onHide, show, domain, trialMode, onUpgradeCTA }) => {
  const [hasErrored, setHasErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [siteAnalytics, setSiteAnalytics] = useState({});
  const history = useHistory()

  const fetchAnalyticsInfo = () => {
    const domainValue = typeof domain === 'object' ? domain.subdomain : domain;
    getSiteAnalytics({domain : domainValue})
      .then(({ data }) => {
        setSiteAnalytics(data);
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false);
        setHasErrored(true);
      })
  }

  useEffect(() => {
    if (show) {
      setIsLoading(true)
      if (trialMode) {
        setIsLoading(false)
      } else {
        fetchAnalyticsInfo();
      }
    }
  }, [show])

  const onClose = () => {
    setHasErrored(false);
    setSiteAnalytics({})
    const { pathname } = window.location
    if (pathname === '/manage/analytics' ) {
      history.push('/manage')
    }
    onHide()
  }

  return <Modal
    show={show}
    size="xl"
    onHide={onClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="p-4">
      {isLoading && <Spinner className="ms-2 text-center" animation="border" variant="primary" />}
      {trialMode && <img src="/assets/img/analytics-preview.jpg" width="100%" />}
      {siteAnalytics?.sharedLink?.url && <>
        <iframe 
          plausible-embed
          id="plausible-iframe"
          src={`${siteAnalytics?.sharedLink?.url}&embed=true&theme=light`}
          width="100%" 
          height="600px" 
          frameborder="0" 
          allowfullscreen>
        </iframe>
      </>}
      {trialMode && <div className={`${styles.trialCTA} mt-4`}>
        <div className="bold">Access all of your metrics</div>
        <Button
          variant="purple"
          onClick={onUpgradeCTA}
        >
          Upgrade to unlock →
        </Button>
      </div>}
      {hasErrored && <ErrorBox onClick={onClose} />}
    </div>
  </Modal>
}

export default AnalyticsModal;