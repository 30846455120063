export const TRANSACTION_INIT = 'TRANSACTION_INIT';
export const transactionInit = (payload) => ({
  type: TRANSACTION_INIT,
  payload
});

export const TRANSACTION_FAILED = 'TRANSACTION_FAILED';
export const transactionFailed = (payload) => ({
  type: TRANSACTION_FAILED,
  payload
});

export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS';
export const transactionSuccess = (payload) => ({
  type: TRANSACTION_SUCCESS,
  payload
});

export const TRANSACTION_PROCESSING = 'TRANSACTION_PROCESSING';
export const transactionProcessing = (payload) => ({
  type: TRANSACTION_PROCESSING,
  payload
});
