import React, { useState, useEffect } from 'react';

const Timer = ({ creationTime, formik }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(creationTime));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remainingTime = calculateTimeRemaining(creationTime);
      setTimeRemaining(remainingTime);

      // Check if time is up
      if (remainingTime.minutes === '00' && remainingTime.seconds === '00') {
        formik.setFieldValue('expired', true);
        clearInterval(intervalId);
      }
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [creationTime]);

  return (
    <div style={{color: 'black', fontSize: "2rem"}}>
      {timeRemaining.minutes}:{timeRemaining.seconds}
    </div>
  );
};

const calculateTimeRemaining = (creationTime) => {
  const currentTime = new Date();
  const start = new Date(creationTime) 
  const expirationDate = new Date(start.getTime() + 15 * 60 * 1000);

  const timeDifference = expirationDate.getTime() - currentTime.getTime();

  if (timeDifference <= 0) {
    // Timer has expired
    return { minutes: '00', seconds: '00' };
    
  }

  const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
  const seconds = String(Math.floor((timeDifference % (1000 * 60)) / 1000)).padStart(2, '0');

  return { minutes, seconds };
};

export default Timer;
