import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import axios from 'axios'

import Header from '../components/HomeHeader'
import { API_ROOT } from '../constants'
import Footer from '../components/Footer'

const Auth = () => {
  const [failed, useFailed] = useState(false)

  const onRetry = () => {
    window.location = '/login'
  }

  useEffect(() => {
    const token = window.location.pathname.split('/').pop()
    axios
      .get(
        `${API_ROOT}/auth?token=${token}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        const urlParams = new URLSearchParams(window.location.search)
        const onboard = urlParams.get('onboard') === 'true'

        if (onboard) {
          window.location = `/pro-welcome?accountconfigured=true`
        } else {
          const redirectUrl = localStorage.getItem('redirect-url');
          if (redirectUrl) {
            localStorage.removeItem('redirect-url');
            window.location = redirectUrl;
          } else {
            window.location = '/manage';
          }
        }
      })
      .catch(() => {
        useFailed(true)
      })
  })

  return (
    <div style={{ height: '100vh' }} className="full-height">
      <Header />
      <div className="mt-5" style={{ height: '500px' }}>
        {failed ? (
          <div className="box">
            <br />
            <h5>Unable to log you in</h5>
            <br />
            <Button onClick={onRetry}>Try Again</Button>
          </div>
        ) : (
          <Spinner animation="border" variant="dark" />
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Auth
