import React from 'react';
import CustomTooltip from "../CustomTooltip";

import './BorderButton.css'

const BorderButton = ({className, style, onClick, label, disabled, tooltip}) => {
  const button = <button
    style={style}
    className={`border-button ${className}`}
    onClick={disabled ? undefined : onClick}
  >
    {label}
  </button>;

  const buttonWithTooltip = <CustomTooltip label={tooltip} upgradeLink>{button}</CustomTooltip>;

  return <React.Fragment>{disabled ? buttonWithTooltip : button}</React.Fragment>
};

export default BorderButton;
