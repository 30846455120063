import {
  TRANSACTION_FAILED,
  TRANSACTION_INIT,
  TRANSACTION_PROCESSING,
  TRANSACTION_SUCCESS
} from "../Pricing/actions";

const initState = {
  transactionState: TRANSACTION_INIT
};

export default (state = initState, action) => {
  switch (action.type) {
    case TRANSACTION_INIT:
      return {
       ...state,
        transactionState: TRANSACTION_INIT
      };
    case TRANSACTION_PROCESSING:
      return {
       ...state,
        transactionState: TRANSACTION_PROCESSING
      };
    case TRANSACTION_FAILED:
      return {
        ...state,
        ...action.payload,
        transactionState: TRANSACTION_FAILED
      };
    case TRANSACTION_SUCCESS:
      return {
       ...state,
        transactionState: TRANSACTION_SUCCESS
      };
    default: return state;
  }
};
