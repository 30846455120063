import React, {useState} from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {PLANS, PLANS_ID} from "../../../constants/plans";

const PlanSelectionDropdown = ({defaultPlanId, onPlanSelected}) => {
  const [plan, setPlan] = useState(defaultPlanId);

  const onChange = (eK, e) => {
    setPlan(e.target.value)

    if (onPlanSelected)
      onPlanSelected(e.target.value)
  }

  return <DropdownButton
    title={PLANS_ID[plan]?.label}
    className="d-inline-block ms-2"
    value={plan} variant="outline-primary"
    onSelect={onChange}
  >
    <Dropdown.Item as="option" key={PLANS.TINY.id} value={PLANS.TINY.id}
                   disabled={plan === PLANS.TINY.id}>{PLANS.TINY.label}</Dropdown.Item>
    <Dropdown.Item as="option" key={PLANS.PRO.id} value={PLANS.PRO.id}
                   disabled={plan === PLANS.PRO.id}>{PLANS.PRO.label}</Dropdown.Item>
    <Dropdown.Item as="option" key={PLANS.PRO_U.id} value={PLANS.PRO_U.id}
                   disabled={plan === PLANS.PRO_U.id}>{PLANS.PRO_U.label}</Dropdown.Item>
  </DropdownButton>
}

export default PlanSelectionDropdown