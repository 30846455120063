import React, { useEffect, useState } from 'react'
import { ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { MAX_FILE_SIZE, MAX_PDF_SIZE, TLDS } from '../../../../constants'
import ContentSelector from 'components/ContentSelector/ContentSelector'
import SelectedFile, { isZipFile } from './foundations/SelectedFile'
import {
  createFileInput,
  getDropzoneAcceptedFiles,
  getTLDs,
  isPDF,
  isValidFile,
  isValidFileSize,
  validateFilesSelection,
  isPhpFile,
  getDomainSuffix,
  onUpgradePlan
} from 'utils/general'
import { PRO_MAX_FILE_SIZES } from 'constants/plans'
import { showUpgradeCardModal } from 'actions'
import {
  findHtmlFilesInZipFile,
  getFileContentType,
  getFileType,
  isIndexFile,
  isIndexFileExisting,
  sortAllHtmlFiles,
  getPhpFiles
} from 'utils/file'
import FileListModal from './modals/FileListModal'

/**
 * @todo: Uploading Pdf or Html file then upload zip show upgrade modal, maybe it should display something else ?
 * @todo: Can rework the plain javascript on adding file
 */

const FileSelectionForm = ({ name, initialValues, domainValues, userProfile, setFieldValue }) => {
  const dispatch = useDispatch()
  const [showFileList, setShowFileList] = useState(false)
  const [htmlFiles, setHtmlFiles] = useState([])
  const [phpFiles, setPhpFiles] = useState([])

  const { selectedFile, draggedFile, addedFiles, fileUploadLimit, indexFile, fileType } = initialValues
  const { domain } = domainValues
  const { productId } = userProfile

  const [acceptedFiles, setAcceptedFiles] = useState(
    getDropzoneAcceptedFiles(getFileContentType(selectedFile?.name) || selectedFile?.fileType || selectedFile?.type)
  )

  useEffect(() => {
    const handleDraggedFile = async (file) => {
      const isTrial = !productId
      const maxSizeMB = fileUploadLimit || PRO_MAX_FILE_SIZES[productId]

      if (isValidFileSize(file, maxSizeMB)) {
        if (isZipFile(file.type)) {
          await zipFileUploaded(file)
        }
        setFieldValue(`${name}.selectedFile`, file)
        setFieldValue(`${name}.fileType`, getFileType(file.name))
        setAcceptedFiles(file.type)

        setFieldValue(`${name}.draggedFile`, null)
      } else {
        dispatch(
          showUpgradeCardModal({
            title: 'Upgrade to upload larger files',
            desc: `Your current plan only allows you to upload ${isPDF(file) ? 'PDFs' : 'files'} less than ${
              isPDF(file) && isTrial ? MAX_PDF_SIZE : maxSizeMB || MAX_FILE_SIZE
            }MB. Upload more on a different plan.`,
            cta: 'Upload larger files',
            onClick: file?.size ? () => onUpgradePlan({fileSize: file?.size}, dispatch) : null
          })
        )
      }
    }

    if (draggedFile) {
      handleDraggedFile(draggedFile)
    }
  }, [draggedFile])

  // TODO: add back when multiple PHP file hosting supported
  const zipFileUploaded = async (file) => {
    const filesFound = await findHtmlFilesInZipFile(file)
    const { containsPhpFile, phpFiles } = await getPhpFiles(file);
    if(containsPhpFile && phpFiles?.length > 0){
      setPhpFiles(phpFiles);
      setShowFileList(containsPhpFile)
    } else {
      setHtmlFiles(filesFound)
      setShowFileList(!isIndexFile(filesFound))
    }
  }

  const isNewHtmlFilesUploaded = (files) => {
    let currentIndex = indexFile

    files.map((file) => {
      if ((file.name || file.path) === 'index.html') {
        currentIndex = file.name || file.path
        setFieldValue(`${name}.indexFile`, currentIndex)
      }
    })

    if (files.length > 1 && !isIndexFileExisting(files)) {
      const htmlFilesFound = sortAllHtmlFiles(files)
      setHtmlFiles(htmlFilesFound)

      if (htmlFilesFound.length > 1) {
        setShowFileList(true)
      } else {
        setFieldValue(`${name}.indexFile`, htmlFilesFound[0])
      }
    }
  }

  const onFileSelected = async (files) => {
    const isTrial = !productId
    let file = files[0] || selectedFile
    const maxSizeMB = fileUploadLimit || PRO_MAX_FILE_SIZES[productId]

    if (file) {
      if (isValidFileSize(file, maxSizeMB)) {
        if (isZipFile(file.type)) {
          await zipFileUploaded(file)
        } else if (isPhpFile(file)) {
          if (file.name !== 'index.php') {
            file = new File([file], 'index.php', { type: file.type, lastModified: file.lastModified })
          }

          setFieldValue(`domainValues.domainSuffix`, TLDS.TIINY_IO)
          setFieldValue(`domainValues.tlds`, [TLDS.TIINY_IO])
        }

        isNewHtmlFilesUploaded(files)

        setFieldValue(`${name}.selectedFile`, file)
        setFieldValue(`${name}.fileType`, getFileType(file.name))
        setAcceptedFiles(file.type)
        if (files.length > 1) {
          setFieldValue(`${name}.addedFiles`, files.slice(1))
        }
      } else {
        dispatch(
          showUpgradeCardModal({
            title: 'Upgrade to upload larger files',
            desc: `Your current plan only allows you to upload ${isPDF(file) ? 'PDFs' : 'files'} less than ${
              isPDF(file) && isTrial ? MAX_PDF_SIZE : maxSizeMB || MAX_FILE_SIZE
            }MB. Upload more on a different plan.`,
            cta: 'Upload larger files',
            onClick: file?.size ? () => onUpgradePlan({fileSize: file?.size}, dispatch) : null
          })
        )
      }
    }
  }

  const addMoreFiles = () => {
    const maxSizeMB = fileUploadLimit || PRO_MAX_FILE_SIZES[productId]
    const input = createFileInput(acceptedFiles)

    input.addEventListener('change', (event) => {
      const file = event.target.files[0]
      file.path = file.name

      if (!validateFilesSelection([selectedFile, ...addedFiles], file)) {
        alert('Please upload files with different names')
        return
      }

      if (isValidFile(file, file, addedFiles, maxSizeMB)) {
        const updatedFiles = [...addedFiles, file]
        setFieldValue(`${name}.addedFiles`, updatedFiles)

        isNewHtmlFilesUploaded([selectedFile, ...updatedFiles])
      } else {
        dispatch(
          showUpgradeCardModal({
            title: 'Upgrade to upload larger files',
            desc: `${isPDF(selectedFile) ? 'PDFs' : 'Files'} should be less than ${
              isPDF(selectedFile) ? MAX_PDF_SIZE : MAX_FILE_SIZE
            }MB on our free plan. Upgrade for more.`,
            cta: 'Upload larger files',
            onClick: file?.size ? () => onUpgradePlan({fileSize: file?.size}, dispatch) : null
          })
        )
      }
    })

    input.click()
  }

  const onRemove = () => {
    if (addedFiles.length === 0) {
      const tlds = getTLDs({ account: userProfile })
      setFieldValue(`${name}.fileType`, null)
      setFieldValue(`${name}.selectedFile`, null)
      setFieldValue(`${name}.indexFile`, null)
      setFieldValue(`${name}.isZipPhpFile`, false)

      setFieldValue(`domainValues.tlds`, tlds)
      setFieldValue(`domainValues.domainSuffix`, getDomainSuffix(domain).tld || tlds[0])

      setHtmlFiles([])
      setPhpFiles([])
      setAcceptedFiles(getDropzoneAcceptedFiles())
    } else {
      const updatedFiles = addedFiles.filter((file) => file.name !== addedFiles[0].name)
      setFieldValue(`${name}.fileType`, getFileType(addedFiles[0].name))
      setFieldValue(`${name}.selectedFile`, addedFiles[0])
      setFieldValue(`${name}.addedFiles`, updatedFiles)
      setAcceptedFiles(
        getDropzoneAcceptedFiles(
          getFileContentType(addedFiles[0]?.name) || addedFiles[0].type || addedFiles[0].fileType
        )
      )
    }
  }

  return (
    <>
      {selectedFile ? (
        <div className="mb-3">
          {/* SHOW UPLOADED FILES */}
          <div className={`${addedFiles.length > 0 ? 'file-list' : ''}`}>
            <SelectedFile
              className="justify-center"
              file={selectedFile || draggedFile}
              indexFile={indexFile}
              onRemove={onRemove}
            />

            {addedFiles?.map((file, index) => (
              <SelectedFile
                key={index}
                file={file}
                indexFile={indexFile}
                onRemove={() => {
                  const updatedFiles = addedFiles.filter((f) => f.name !== file.name)
                  setFieldValue(`${name}.addedFiles`, updatedFiles)
                }}
              />
            ))}
          </div>
          {/* END SHOW UPLOADED FILES */}

          {/* ADD FILES */}
          {!isZipFile(selectedFile?.type || selectedFile?.fileType) && !isPhpFile(selectedFile) && (
            <Button
              className="mt-2 ms-auto d-block w-100"
              variant="outline-primary"
              size="sm"
              onClick={(event) => addMoreFiles(event)}
            >
              + Add more files
            </Button>
          )}
          {/* END ADD FILES */}
        </div>
      ) : (
        <>
          {/* DROPZONE */}
          <ContentSelector
            className="tr-landing-upload-file-zone m-3"
            onDrop={async (files) => {
              await onFileSelected(files)
            }}
            onTemplateSelected={(template) => {
              setFieldValue(`${name}.selectedFile`, template)
              setFieldValue(`${name}.fileType`, getFileType(template.path))
            }}
            acceptedFiles={`${acceptedFiles}',.php, text/php'`} // PHP only available in logged in view
          />
          {/* END DROPZONE */}
        </>
      )}
      <ErrorMessage name={`${name}.selectedFile`} component="div" className="validation-error text-center" />

      {/** HTML FILE PICKER */}
      {htmlFiles?.length > 0 && (
        <FileListModal
          show={showFileList}
          onHide={() => setShowFileList(false)}
          onFileSelected={(file) => {
            setFieldValue(`${name}.indexFile`, file)
            setShowFileList(false)
          }}
          filesList={htmlFiles}
          indexFile={indexFile}
          title="Which file should be your homepage?"
          subtitle="We've found multiple HTML files in your Zip"
        />
      )}
      {/** END OF HTML FILE PICKER */}

      {/** PHP FILE PICKER */}
      {phpFiles?.length > 0 && (
        <FileListModal
          show={showFileList}
          onHide={() => setShowFileList(false)}
          onFileSelected={(file) => {
            setFieldValue(`${name}.indexFile`, file)
            setFieldValue(`${name}.isZipPhpFile`, true)
            setFieldValue(`domainValues.domainSuffix`, TLDS.TIINY_IO)
            setFieldValue(`domainValues.tlds`, [TLDS.TIINY_IO])
            setShowFileList(false)
          }}
          filesList={phpFiles}
          title="Which file should be your homepage?"
        />
      )}
      {/** END OF HTML FILE PICKER */}
    </>
  )
}

export default FileSelectionForm
