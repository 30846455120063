import React, {useState, useEffect} from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setQrCodeData, setQrCodeModal, setEmbedData } from 'Manage/actions'
import { getDirectFileLink } from '../../../utils/general'
import './SuccessBox.css'
import Share from 'components/ShareModal/Share'
import { setShowPaymentModal } from 'actions'
import { PLANS } from "constants/plans";
/**
 * @todo : make this component dynamics for other use cases (later maybe)
 */

const SuccessBox = ({ userProfile, site, files, onClose, onReset }) => {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)
  const { subdomain, passwordProtected, type, filename, lastUpdated, created } = site
  const { productId } = userProfile

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({showPaymentModal: true, upgradePlanId: planId}))
 }

/**  @todo: Success box for porcessinig sites */
  useEffect(() => {
    if (site?.linkStatus?.toLowerCase() === 'processing') {
      setProcessing(true)
    } else setProcessing(false)
  }, [site?.linkStatus])

  return (
    <div className="success-box">
      <div onClick={onClose}>
        <img className="exit" src="delete.png" alt="quit" />
      </div>
      <h3>BOOM!</h3>
      <img className="celebrate" src="celebrate.gif" alt="celebrate" />
      <div>
        <h5 className="domain">
          <a href={`https://${subdomain}`} target="_blank">
            {subdomain}
          </a>
        </h5>
        {processing ? <div className="d-flex flex-row mt-3 mb-5 align-items-center gap-2 justify-center">
          <Spinner animation="grow" variant="secondary" size="sm"  />
          <span className="d-inline">Currently processing your site</span>
        </div> : 
        <div className="d-flex flex-column mt-2 mb-3 align-items-center">
          <div className="mt-2 d-flex align-items-center flex-row">
            {files.length > 0 && (
              <Button className="me-2" variant="outline-primary" onClick={() => onReset(site, files)}>
                Update
              </Button>
            )}
            <Button onClick={() => window.open(`https://${subdomain}`, '_blank')}>View Site</Button>
          </div>
          <Share
            passwordProtected={passwordProtected}
            trial={!!!productId}
            url={`https://${subdomain}`}
            directFileUrl={getDirectFileLink(`https://${subdomain}`, filename, 'pdf', lastUpdated || created)}
            onQRClick={() => {
              dispatch(setQrCodeModal(true))
              dispatch(setQrCodeData(site))
            }}
            onEmbed={() => {
              dispatch(setEmbedData(true, site))
            }}
            type={type}
            onUpgrade={() => onUpgrade(PLANS.TINY.id)}
          />
          <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
            <span className="d-inline">Something broken? -</span>
            <a
              className="d-inline link"
              href="https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/"
              target="_blank"
            >
              Read our documentation
            </a>
          </div>
        </div>}
      </div>
    </div>
  )
}
export default SuccessBox
