import {API_ROOT} from "../constants";
import api from "../utils/api";

export const customDomain = (formData, config) => api
  .post(`${API_ROOT}/pro/custom-domain`, formData, config);

export const validateDomain = (data, config) => api
  .post(`${API_ROOT}/pro/custom-domain/validate`, data, config);

export const deleteDomain = (data, config) => api
  .post(`${API_ROOT}/pro/custom-domain/delete`, data, config);

export const autoConnectDomainAdd = (data, config) => api
  .post(`${API_ROOT}/domain/add`, data, config);

export const autoConnectDomainFinish = (data, config) => api
  .post(`${API_ROOT}/domain/validate`, data, config);

export const autoConnectDomainFailed = (data, config) => api
  .post(`${API_ROOT}/domain/fail`, data, config);

export const autoConnectDomainRetry = (data, config) => api
.post(`${API_ROOT}/domain/retry`, data, config);

export const getAvailableDomains = (data, config) => api
  .get(`${API_ROOT}/domain/search-domains`, data, config);

export const purchaseDomain = (data, config) => api
  .get(`${API_ROOT}/domain/purchase-domain`, data, config);

export const checkDomain = (data, config) => api
  .get(`${API_ROOT}/domain/check-domain`, data, config);

export const makeEnomStripePayment = (data, config) => api
  .post(`${API_ROOT}/pro/stripe-enom-payment`, data, config);