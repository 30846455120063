import {API_ROOT} from "../constants";
import axios from "axios";
import api from "../utils/api";

const updateSite = (formData, config) => axios
  .post(`${API_ROOT}/update-domain`, formData, config);

export const listFiles = (formData, config) => api
  .post(`${API_ROOT}/pro/list-files`, formData, config);

export const getCode = (formData, config) => api
  .get(`${API_ROOT}/pro/code`, formData, config);

export const publishCode = (formData, config) => api
  .post(`${API_ROOT}/pro/publish-code`, formData, config);

export const updatePDF = (formData, config) => api
  .post(`${API_ROOT}/pro/update-pdf`, formData, config);

export default updateSite;
