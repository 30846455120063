import {CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import UpgradeCard, {UPGRADES} from "../../components/UpgradeCard";
import React from "react";

const UpsellWidget = ({ showUpgradeView }) =>
    <div className="info-box mt-5 ms-auto tr-upgrade-carousel">
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={180}
        totalSlides={3}
        isPlaying={true}
        interval={5000}
      >
        <Slider>
          <Slide index={0}>
            <UpgradeCard
              desc={UPGRADES[0].desc}
              cta={UPGRADES[0].cta}
              colourScheme={UPGRADES[0].colorScheme}
              onClick={showUpgradeView}
            />
          </Slide>
          <Slide index={1}>
            <UpgradeCard
              desc={UPGRADES[1].desc}
              cta={UPGRADES[1].cta}
              colourScheme={UPGRADES[1].colorScheme}
              onClick={showUpgradeView}
            />
          </Slide>
          <Slide index={2}>
            <UpgradeCard
              desc={UPGRADES[2].desc}
              cta={UPGRADES[2].cta}
              colourScheme={UPGRADES[2].colorScheme}
              onClick={showUpgradeView}
            />
          </Slide>
        </Slider>
        <DotGroup
          className="carousel-dots"
        />
      </CarouselProvider>
    </div>

export default UpsellWidget;
