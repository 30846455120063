import { API_ROOT } from 'constants'
import { ACTION_UPDATE, ACTION_EXTEND, DISPLAY_MODE_STANDARD, ACTION_CREATE } from './interface'
import { createSite, deleteSiteFiles, getSiteContents, updateSiteContents } from 'services/v2/pro'
import { formDataToJson, isEmpty, zipMultipleFiles } from 'utils/general'
import api from 'utils/api'
import { DISPLAY_MODE_EMAIL_GATE } from 'components/CreateSiteModal/CreateSiteForm/components/DisplayModeSelect/DisplayModeSelect'

/**
 * Query
 */

export const getSite = async (action, subdomain) => {
  switch (action) {
    case ACTION_UPDATE: {
      try {
        const { data } = await getSiteContents({ domain: subdomain })

        const { link, files } = data

        return {
          link,
          siteFiles: files
        }
      } catch (error) {
        throw new Error(error)
      }
    }
    case ACTION_EXTEND: {
      break
    }
    default: {
      return {
        link: null,
        files: []
      }
    }
  }
}

/**
 * Mutation
 */

export const updateSite = async (domain, values, executeRecaptcha, uploadProgress) => {
  try {
    const { filesToAdd, indexFile, isZipPhpFile } = values
    const formData = new FormData()

    let link, files

    for (let i = 0; i < filesToAdd?.length; i++) {
      if (filesToAdd[i].isTemplate === true) {
        formData.append('template', filesToAdd[i].path)
      } else {
        formData.append('filesToAdd', filesToAdd[i])
      }
    }

    Object.keys(values).forEach((key) => {
      if (key !== 'filesToAdd' && !isEmpty(values[key])) {
        if (key === 'filesToRemove') {
          for (let i = 0; i < values[key].length; i++) {
            formData.append('filesToRemove', JSON.stringify({ id: values[key][i].id }))
          }
        }

        if (key === 'siteSettings') {
          formData.append(key, JSON.stringify(values[key]))
        }

        if (key === 'newDomain') {
          formData.append(key, values[key])
        }

        if (key === 'indexFile') {
          formData.append('indexFile', indexFile)
        }

        if(key === 'siteType'){
          formData.append(key, values[key])
        }

        if (key === 'isZipPhpFile') {
          formData.append('isZipPhpFile', isZipPhpFile)
        }
      }
    })

    const recaptchaValue = await executeRecaptcha('pro')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    const { data } = await updateSiteContents(domain, formData, headers, uploadProgress)

    link = data.link
    files = data.files

    return {
      link: link,
      files: files
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const createNewSite = async (values, executeRecaptcha, uploadProgress) => {
  try {
    const { filesToAdd, indexFile, isZipPhpFile } = values
    const formData = new FormData()

    let link, files

    for (let i = 0; i < filesToAdd?.length; i++) {
      if (filesToAdd[i].isTemplate === true) {
        formData.append('template', filesToAdd[i].path)
      } else {
        formData.append('filesToAdd', filesToAdd[i])
      }
    }

    Object.keys(values).forEach((key) => {
      if (key !== 'filesToAdd' && !isEmpty(values[key])) {
        if (key === 'siteSettings') {
          formData.append(key, JSON.stringify(values[key]))
        }

        if (key === 'newDomain') {
          formData.append(key, values[key])
        }

        if (key === 'domainSuffix') {
          formData.append(key, values[key])
        }

        if (key === 'indexFile') {
          formData.append('indexFile', indexFile)
        }

        if(key === 'siteType'){
          formData.append(key, values[key])
        }

        if (key === 'isZipPhpFile') {
          formData.append('isZipPhpFile', isZipPhpFile)
        }
      }
    })



    const recaptchaValue = await executeRecaptcha('pro')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    const { data } = await createSite(formData, headers, uploadProgress)

    link = data.link
    files = data.files

    return {
      link: link,
      files: files
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
