import React from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {Formik} from "formik";

const OnBoardingSurvey = ({ onContinue, status }) =>
  <Formik
    onSubmit={onContinue}
    initialValues={{}}
  >
    {({handleSubmit, values, handleChange}) =>
      <Form onSubmit={handleSubmit}>
        <h3 className="mb-2 color-purple">Let's improve your experience</h3>
        <p>Please tell us a little about yourself and why you’re using tiiny.host.</p>
        <Form.Group className="mt-4">
          <Form.Label>How did you hear about us?</Form.Label>
          <Form.Control
            name="discovery"
            as="select"
            type="text"
            value={values.discovery}
            onChange={handleChange}
          >
            <option value="">Select...</option>
            <option value="SEO">Google or equivalent</option>
            <option value="Referral">Referral</option>
            <option value="Blog">Blog</option>
            <option value="YouTube">YouTube</option>
            <option value="Twitter">Twitter</option>
            <option value="Other">Other</option>
          </Form.Control>
        </Form.Group>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Employment</Form.Label>
              <Form.Control
                name="job"
                as="select"
                value={values.dJob}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="Freelancer">Freelancer</option>
                <option value="Employed">Employed</option>
                <option value="Student">Student</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                name="role"
                as="select"
                value={values.dRole}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="Developer/Engineer">Developer/Engineer</option>
                <option value="Designer">Designer</option>
                <option value="Marketing">Marketing</option>
                <option value="Publishing">Publishing</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Use case</Form.Label>
          <Form.Control
            name="useCase"
            as="select"
            value={values.dUseCase}
            onChange={handleChange}
          >
            <option value="">Select...</option>
            <option value="Prototyping">Prototyping</option>
            <option value="App-Testing">Testing</option>
            <option value="Client-Demo">Demo for a client</option>
            <option value="Personal website/project">Personal website/project</option>
            <option value="Learning">Learning</option>
            <option value="Marzipano">Marzipano</option>
            <option value="In5">In5 Hosting</option>
            <option value="Other">Other</option>
          </Form.Control>
        </Form.Group>
        <Button
          type="submit"
          disabled={status.loading}
          className="mt-3 float-right tr-onboarding-questionnaire-submit"
        >
          {status.loading ? <Spinner animation="border" size="sm"/> : 'Complete'}
        </Button>
      </Form>
    }
  </Formik>

export default OnBoardingSurvey