import React from 'react';
import styles from './MoreOptions.module.css';
import {FILE_TYPE} from "../../constants";
import UpgradeBadge from "../UpgradeBadge/UpgradeBadge";

const Item = ({ icon, alt, label, onClick, disabled, onUpgrade }) => (
  <div className={`${styles.contentBox} ${disabled ? styles.disabled : ''}`} onClick={!disabled ? onClick : null}>
    <img src={`/assets/icons/${icon}`} alt={alt} className={styles.img} />
    <span className={styles.contentText}>{label}</span>
    <UpgradeBadge show={disabled} onUpgrade={onUpgrade}/>
  </div>
)

const MoreOptions = ({
  onEditHTML,
  onEditPDF,
  onArchive, 
  onDownload, 
  onAnalytics, 
  onQRCode, 
  onDelete, 
  onCopy, 
  onReplaceFile,
  type,
  onEmbed,
  onCustomisePasswordPage,
  passwordProtected,
  enableEmailGate,
  linkPreview,
  onShare,
  trial,
  link,
  onUpgrade
}) => {
  const phpFile = link.includes('tiiny.io')
  return (
    <div className={styles.menu}>
      <div className={styles.contentContainer}>
        {!linkPreview && (
          <Item
            icon="copy-link.svg"
            alt="copylink"
            label="Copy link"
            onClick={onCopy}
          />
        )}
        {!trial && <Item
          icon="share.svg"
          alt="sharelink"
          label="Share link"
          onClick={onShare}
        />}
        <Item
          icon="qr-code.svg"
          alt="qrcode"
          label="View QR code"
          onClick={onQRCode}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
        />
        <Item
          icon="embed.svg"
          alt="embed"
          label="Embed this link"
          onClick={onEmbed}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
        />
      </div>
      { !phpFile && <div className={styles.contentContainer}>
        <Item
          icon="chart-bar.svg"
          alt="analytics"
          label="View analytics"
          onClick={onAnalytics}
        />
      </div>}
     {!phpFile && <div className={styles.contentContainer}>
        <Item
          icon="edit.svg"
          alt="edit"
          label="Edit"
          onClick={type === FILE_TYPE.PDF ? onEditPDF : onEditHTML}
          disabled={linkPreview}
          onUpgrade={onUpgrade}
        />
        <Item
          icon="replace.svg"
          alt="replace"
          label="Replace existing file"
          onClick={onReplaceFile}
        />
        {(passwordProtected || enableEmailGate) && (
          <Item
            icon="settings.svg"
            alt="Customize password page"
            label={`Customize ${enableEmailGate ? 'email capture' : 'password'} page`}
            onClick={onCustomisePasswordPage}
          />
        )}
      </div>}
      {!phpFile && <div className={styles.contentContainer}>
        <Item
          icon="download-white.svg"
          alt="download"
          label="Download copy"
          onClick={onDownload}
        />
        <Item
          icon="archive-box.svg"
          alt="archive"
          label="Archive"
          onClick={onArchive}
        />
        <Item
          icon="trash.svg"
          alt="delete"
          label="Delete"
          onClick={onDelete}
        />
      </div>}
    </div>
  );
};

export default MoreOptions;
