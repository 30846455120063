import React from 'react';

import './Feature.css';

const Feature = ({title, text, svg, className, descClassName = "line-height-m", titleClassName = 'bold', children}) =>
  <div className="feature row container text-center">
      <div className={`feature-desc col-10 col-lg-6 me-auto ms-auto ${className}`}>
      <h3 className={`feature-title-b text-start ${titleClassName}`}>{title}</h3>
      <p className={`feature-text mt-3 text-start op-6 ${descClassName}`}>{children || text}</p>
    </div>
    <div className="col-10 col-lg-4 ms-auto me-auto">
      <img className="op-7" src={svg} height="100" />
    </div>
</div>;

export default Feature;
