import React from 'react';
import CustomTooltip from "../CustomTooltip";

import './BorderButton.b.css'

const BorderButton = ({className = '', style, onClick, label, disabled, tooltip, icon}) => {
  const button = <button
    style={style}
    className={`border-button-b ${className}`}
    onClick={disabled ? undefined : onClick}
  >
    {icon && icon}
    {label}
  </button>;

  const buttonWithTooltip = <CustomTooltip label={tooltip} upgradeLink>{button}</CustomTooltip>;

  return <React.Fragment>{disabled ? buttonWithTooltip : button}</React.Fragment>
};

export default BorderButton;
