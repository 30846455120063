import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import QRCode from 'react-qr-code';
import { useDispatch } from "react-redux";
import LogoIcon from './favicon.ico'
import { setQrCodeData } from "Manage/actions";

const QRCodeModal = ({onHide, show, domain,}) => {
    const [qrSize, setQrSize] = useState(300);

    const dispatch = useDispatch();

    const onImageDownload = () => {
      const svg = document.getElementById("QRCode");
      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
    
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 2048;
        canvas.height = 2048;
    
        const ctx = canvas.getContext("2d");
    
        ctx.imageSmoothingEnabled = false;
        ctx.imageSmoothingQuality = "high"; 
    
        const scaleFactor = Math.min(canvas.width / img.width, canvas.height / img.height);
    
        const scaledWidth = img.width * scaleFactor;
        const scaledHeight = img.height * scaleFactor;
    
        const x = (canvas.width - scaledWidth) / 2;
        const y = (canvas.height - scaledHeight) / 2;
    
        ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
    
        const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode.png";
        downloadLink.href = pngFile;
    
        downloadLink.click();
      };
    
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
   
    const handlePrint = () => {
      const svg = document.getElementById('QRCode');
      if (svg) {
        const svgData = new XMLSerializer().serializeToString(svg);
        const dataUri = 'data:image/svg+xml;base64,' + btoa(svgData);
    
        const img = new Image();
        img.src = dataUri;
    
        img.onload = () => {
          const printWindow = window.open('', '_blank');
          printWindow.document.write('<html><head><title>Print</title></head><body>');
          
          printWindow.document.write('<img src="' + img.src + '" style="width: 100%;" />');
          
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
          };
        };
      }
    };
    

  const getDomain = (siteRecord) => {
    const subDomain = siteRecord?.subdomain;
    const domain = siteRecord || '';
    return subDomain || domain;
  };
    
  const onClose = () => {
    dispatch(setQrCodeData({}))
    onHide()
  }

  return <Modal
    show={show}
    onHide={onClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className={`p-4 d-flex  align-items-center flex-column `}>
      <h1 className="bold font-26 color-purple">Your link's QR code</h1>
        <QRCode
          id="QRCode"
          value={`https://${getDomain(domain)}`}
          size={qrSize}
          level={'H'}      
          bgColor="#FFFFFF" 
        />

      <div className="font-small grey">Use your phone camera to scan this code.</div>
      <div className="mt-4 d-flex">
        <Button className="me-2 d-flex gap-2 align-items-center" variant="outline-primary" onClick={() => handlePrint()}>
        <img height="16" src="/assets/icons/print.svg"  alt="tooltip"/>
          Print
        </Button>
        <Button
         onClick={() =>{
          onImageDownload()
         }}
         className="me-2 d-flex gap-2 align-items-center"   >
        <img height="16" src="/assets/icons/download.svg"  alt="tooltip"/>
         Download
         </Button>
      </div>
    </div>
  </Modal>
}

export default QRCodeModal;