import moment from "moment";
import {hashString} from "./general";

export function trackUpload(plan, size, type, email) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'fileUploaded',
    plan, // 'free', 'Solo', 'Pro, 'Student
    size, //Size of the file. Should be a number, not a string. The measuring unit can be the same one used in the back office but should be consistent (if you are using ‘Mo’ or ‘Mb’, use it for all cases)
    type, // 'html', 'zip' or 'pdf'
    userId: email ? hashString(email) : 'N/A' // if the user is logged in, this number must be replaced with an actual User ID, otherwise, replace with ‘N/A’
  });
}

export function trackNewUser(plan, email) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'accountCreated',
    plan, // 'free', 'paid'
    userId: email ? hashString(email) : 'N/A' //userID allocated to this user. this number must be replaced with an actual User ID
  });
}

export function trackOnboardingSurvey(email, hearAboutUs = 'N/A', job = 'N/A', role = 'N/A', useCase = 'N/A') {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'onboardingComplete',
    hearAboutUs, // 'referral', 'blog', … or 'N/A' if not filled
    job, // 'freelancer', 'student', … or 'N/A' if not filled
    role, // 'designer', 'marketing', … or 'N/A' if not filled
    useCase, // 'prototyping', 'testing', … or 'N/A' if not filled
    userId: email ? hashString(email) : 'N/A'
  });
}

export function calcUpgradeTime(accountCreated) {
  const createdDate = moment(accountCreated);
  const todayDate = moment();
  return todayDate.diff(createdDate, 'days');
}

export function trackSubscriptionUpgradeInfo(email, upgradeTime = 'N/A', upgradeReason = 'N/A') {
  //window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'upgradeInfo',
    userId: email ? hashString(email) : 'N/A',
    upgradeTime,
    upgradeReason,
  });
}

export function trackSubscriptionUpgrade(email, planType, value, period, transaction_id, source) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      userId: email ? hashString(email) : 'N/A', // if the user is logged in, this number must be replaced with an actual User ID, otherwise, replace with ‘N/A’
      currency: 'USD', // mandatory
      value, // Total paid by user, number not string
      transaction_id, // mandatory, if not available you create a random series of numbers and letters
      source,
      items: [{
        item_name: planType, // Enter the paid plan version : 'pro', 'solo','student'
        price: value, // Total paid by user
        item_category: period, // Monthly or annual
        quantity: 1
      }]
    }
  });
}