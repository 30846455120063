import React, {useState} from 'react';
import {Button, FormControl, InputGroup, Spinner} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";

import {updateTeamMembers} from "../../../../services/manage";
import {STAGE_FAILED, STAGE_SUCCESS_MANUAL, STAGE_SUCCESS} from "../AddTeamMembersModal";

import styles from '../AddTeamMembersModal.module.css';

const ADDITIONAL_TEAM_MEMBERS_PRICE = 8;

const schema = Yup.object({
  email: Yup.string()
    .email('Please end a valid email')
    .required()
});

const formatRequest = (existingTeamMembers, teamMemberList) => {
  const add = [];
  const remove = [];
  teamMemberList.map((email) => {
    if (!existingTeamMembers.includes(email)) {
      add.push(email)
    }
  })
  existingTeamMembers.map((email) => {
    if (!teamMemberList.includes(email)) {
      remove.push(email)
    }
  })
  return {
    add,
    remove
  }
}

const isListSame = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index])

const Add = ({ existingTeamMembers = [], teamMemberLimit, onHide, setStage }) => {
  const [loading, setLoading] = useState(false);
  const [teamMemberList, setTeamMemberList] = useState(existingTeamMembers);

  const updateMembers = () => {
    setLoading(true)
    updateTeamMembers(formatRequest(existingTeamMembers, teamMemberList))
      .then(({ data }) => {
        setLoading(false);
        setStage(data.manual ? STAGE_SUCCESS_MANUAL : STAGE_SUCCESS )
      })
      .catch(() => {
        setLoading(false);
        setStage(STAGE_FAILED)
      })
  }

  const removeMember = (email) =>
    setTeamMemberList(teamMemberList.filter(item => item !== email))

  const addMember = (email) => {
    if (!teamMemberList.includes(email.toLowerCase())) {
      setTeamMemberList([...teamMemberList, email.toLowerCase()])
    }
  }

  return <>
    <div className="p-3">
      <h2 className="mb-2 bold color-purple-2 font-22">
        Manage team members
      </h2>
      <div className="grey">Add or remove team members who have access to this account.</div>
      <div className="mt-4">
        {teamMemberList.map((email) => (<div className="flex mt-1">
          <div className="flex-grow-1">{email}</div>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => removeMember(email)}
          >
            Remove
          </Button>
        </div>))}
        {teamMemberList.length === 0 && <span><i>You have no team members</i></span>}
      </div>
      <hr />
      <div className="grey small mt-2 ms-1 me-1">
        <img src="/assets/icons/info-grey.svg" height={12} className="me-2" />
        You can add up to <b>{teamMemberLimit} total team members</b> in your current plan or purchase additional seats if you need more.
      </div>
      <Formik
        validationSchema={schema}
        onSubmit={({email}, { setFieldValue, setFieldTouched }) => {
          addMember(email)
          setFieldTouched('email', false)
          setFieldValue('email', '')
        }}
        initialValues={{}}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <InputGroup className="input-domain">
              <FormControl
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
              />
              <Button type="submit" variant="outline-primary">Add</Button>
            </InputGroup>
            <ErrorMessage name="email" component="div" className="validation-error" />
          </form>
        )}
      </Formik>
      <hr/>
      {teamMemberList.length > teamMemberLimit && <div className={styles.chargeText}>
        <div>You will be billed an <b>additional ${(teamMemberList.length - teamMemberLimit) * ADDITIONAL_TEAM_MEMBERS_PRICE} per month</b> on <b>pro-rata basis starting today</b>.</div>
        <span className="op-6 font-small">We will automatically charge your current payment method.</span>
      </div>}
      <div className="mt-3">
        <Button
          className="float-right"
          type="submit"
          disabled={loading || isListSame(existingTeamMembers, teamMemberList)}
          variant="primary"
          onClick={updateMembers}
        >
          {loading && <Spinner size="sm" animation="border"/>}
          {!loading && 'Update'}
        </Button>
        <Button
          className="float-right me-2"
          disabled={loading}
          variant="outline-primary"
          onClick={onHide}
        >
          Cancel
        </Button>
      </div>
    </div>
  </>
}

export default Add;
