import React from 'react';
import BorderButton from "../BorderButton";
import {withRouter} from "react-router";
import text from "../../Pricing/pricing.string";

const FeatureBox = ({header, imgSrc, desc}) =>
  <div className="col-10 col-md-6 mt-5 ms-auto me-auto flex text-start">
    <div>
      <img src={imgSrc} height="40px" alt="Icon"/>
    </div>
    <div className="ms-4">
      <h5 className="bold" style={{fontSize: '15px'}}>{header}</h5>
      <p className="op-6" style={{fontSize: '15px'}}>{desc}</p>
    </div>
  </div>;

const ProFeatures = ({history, showCTA, strings = text.GB, hideTitle}) => <React.Fragment>
  {!hideTitle && <>
    <h3 className="bold text-start">Upgrade for more</h3>
    <p className="pricing-desc op-6">{strings.desc}</p>
  </>}
  {showCTA && <div className="text-start mt-3">
    <BorderButton label="Learn More →" onClick={() => history.push('/pricing')}/>
  </div>}
  <div className={`row mt-4 ${!showCTA && 'ms-md-4 me-md-4 mt-md-5 mb-5'}`}>
    <FeatureBox
      header={strings.customDomainSSL}
      imgSrc="/assets/icons/com-black.svg"
      desc={strings.customDomainSSLDesc}
    />
    <FeatureBox
      header={strings.largeHosting}
      imgSrc="/assets/icons/endless-black.svg"
      desc={strings.largeHostingDesc}
    />
    <FeatureBox
      header={strings.passwordProtection}
      imgSrc="/assets/icons/lock-black.svg"
      desc={strings.passwordProtectionDesc}
    />
    <FeatureBox
      header={strings.adFree}
      imgSrc="/assets/icons/no-ads-black.svg"
      desc={strings.adFreeDesc}
    />
  </div>
</React.Fragment>;

export default withRouter(ProFeatures);
