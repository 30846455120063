import React from "react";
import {Button, Modal} from "react-bootstrap";
import DNSTable from '../../CustomDomainModal/components/DNSTable'
import  '../CustomDomain.css';

const DOC_LINK = 'https://helpdesk.tiiny.host/en/category/custom-domains-141gxwm/';

const Details = ({ onClose, show, dnsData, domain }) => {
  return <Modal
    show={show}
    onHide={onClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="m-4">
      <h3>Connecting your domain</h3>
      <a target="_blank" href={DOC_LINK}>
        <Button variant="outline-secondary" size="sm">View documentation</Button>
      </a>
      <p className="grey mt-4">The following DNS records should have been added to your DNS settings:</p>
      <div className="records">
        <DNSTable data={dnsData} domain={domain}/>
      </div>
    </div>
    <Button
      className="action-btn round-top-0"
      onClick={onClose}
    >
      Done
    </Button>
  </Modal>
}

export default Details;