import React from 'react'
import CustomTooltip from '../CustomTooltip'

import styles from './IconButton.module.css'

const IconButton = ({ className = '', disabled, onClick, src, tooltip, label, link }) => {
  const button = (
    <button className={`${className} ${styles.container} ${disabled && "disabled-button-important"}`} onClick={onClick} disabled={disabled}>
      <img height="16" src={src} alt="tooltip" />
      {label && <span className="ms-2 text-black">{label}</span>}
    </button>
  )

  const component = tooltip ? (
    <CustomTooltip label={tooltip} link={link}>
      {button}
    </CustomTooltip>
  ) : (
    button
  )

  return <>{component}</>
}

export default IconButton
