import { isDevSite } from "../../../utils/general";

const PRODUCT_ID_PROD = 'prod_Km96FO0XbwfkoI';
const PRODUCT_ID_DEV = 'prod_Km96YFMtbmgLGx';

const PRICE_ID_YEARLY_PROD = 'price_1K6aoVKnX1nnNv6IhdBNZ0Y7';
const PRICE_ID_YEARLY_DEV = 'price_1K6ao7KnX1nnNv6If6m7z7QW';

const PRICE_ID_MONTHLY_PROD = 'price_1K6aoVKnX1nnNv6IooEjX9YL';
const PRICE_ID_MONTHLY_DEV = 'price_1K6ao7KnX1nnNv6I5PMZZo7e';

const PADDLE_PRICE_ID_YEARLY_PROD = 747295;
const PADDLE_PRICE_ID_YEARLY_DEV = 0;

const PADDLE_PRICE_ID_MONTHLY_PROD = 747294;
const PADDLE_PRICE_ID_MONTHLY_DEV = 0;

const PADDLE_PRICE_ID_YEARLY = isDevSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD;
const PADDLE_PRICE_ID_MONTHLY = isDevSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD;

const PRODUCT_ID = isDevSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD;
const PRICE_ID_MONTHLY = isDevSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD;

const PRO_U_PLAN_3 = {
  id: PRODUCT_ID,
  label: 'Pro L',
  prices: {
    yearly: { id: PRICE_ID_YEARLY, value: 792, paddleLink: 'https://buy.paddle.com/product/747295', paddleId: PADDLE_PRICE_ID_YEARLY },
    monthly: { id: PRICE_ID_MONTHLY, value: 89, paddleLink: 'https://buy.paddle.com/product/747294',paddleId: PADDLE_PRICE_ID_MONTHLY }
  },
  fileSizeLimit: 1000,
  customDomainsLimit: 5,
}

export default PRO_U_PLAN_3;
