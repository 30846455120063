import React from 'react';
import styles from './ReviewCard.module.css';
import CTAButton from "../CTAButton/CTAButton";

const quotes = [
  {
    id: 1,
    name: 'Khaled',
    quote: 'Shocked by the simplicity of user-flow, it took me 2 mins exactly to deploy one of client\'s websites!',
    img: 'quote-auth-2.jpg',
    company: 'Developer, web25.io'
  },
  {
    id: 4,
    name: 'Myra',
    quote: 'If you want the simplest, easiest way then use Tiiny Host.',
    img: 'quote-auth-1.jpg',
    company: 'Customer support, In5'
  },
  {
    id: 6,
    name: 'Robert',
    quote: "Everything else I looked at basically required developer resources or was too basic/complicated.",
    img: 'quote-auth-7.jpg',
    company: 'Designer'
  },
  {
    id: 3,
    name: 'Cliff',
    quote: 'Customer support was very knowledgeable and explained everything clearly.',
    img: 'quote-auth-3.jpg',
    company: 'swpstks.com'
  },
  {
    id: 5,
    name: 'Peter',
    quote: 'I can’t believe that trillion dollar companies can’t produce software half as good as yours',
    img: 'quote-auth-6.jpg',
    company: 'Designer'
  },
  {
    id: 7,
    name: 'Zac',
    quote: "Tiiny worked perfectly for me. I used to host my architectural design portfolio and provided my url on my resume.",
    img: 'quote-auth-5.jpg',
    company: 'Architect'
  },
  {
    id: 2,
    name: 'Nurbek',
    quote: 'I found the website very intuitive to use, and the ability to create custom domains is amazing. ',
    img: 'quote-auth-4.jpg',
    company: 'Renea'
  },
];

const ReviewCard = () => {
  return (
    <div className={styles.wrapperOuter}>
    <div className={styles.wrapper}>
      <div className={styles.page}>
        <div className={styles.content}>
          <div>
            <div className={styles.pictures}>
              <div className={styles.overlapContainer}>
                <div className={styles.roundedImage}>
                  <img className={styles.img} src={'assets/img/quote-auth-7.jpg'} alt=""/>
                </div>
                <div className={styles.roundedImage}>
                  <img className={styles.img} src={'assets/img/quote-auth-5.jpg'} alt=""/>
                </div>
                <div className={styles.roundedImage}>
                  <img className={styles.img} src={'assets/img/quote-auth-6.jpg'} alt=""/>
                </div>
                <div className={styles.roundedImage}>
                  <img className={styles.img} src={'assets/img/quote-auth-1.jpg'} alt=""/>
                </div>
              </div>
              <span className={styles.subtext}>100k+ happy users</span>
            </div>
            <span className="font-22">★ ★ ★ ★ ★</span>
          </div>
          <div className={styles.contentText}>
            <div className={styles.title}>Rave reviews.</div>
            <div className={styles.subtitle}>We're loved by thousands because we're simple.</div>
          </div>
          <CTAButton
            className={styles.button}
            onClick={() => window.scrollTo(0, 0)}
            label="Try it yourself"
          />
        </div>
        <div className={styles.cardContainer}>
          <div className="d-none d-md-block" style={{ width: 50, background: 'none' }} />
          {quotes.slice(0, 6).map((review) => {
            return (
              <div className={styles.reviewCard} key={review.id}>
                <div className={styles.profiles}>
                  <div className={styles.imgWH}>
                    <img className={styles.img} src={`assets/img/${review.img}`}/>
                  </div>
                  <div className={styles.names}>
                    <h6 className={styles.userName}>{review.name}</h6>
                    <div className={styles.companyName}>- {review.company}</div>
                  </div>
                </div>
                <div className={styles.review}>"{review.quote}"</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};

export default ReviewCard;
