import React from 'react'
import {Button} from "react-bootstrap";

import './ErrorBox.css'

export const ErrorBox = ({ className, setRootState, message, hideButton, onClick, ctaLabel }) => {
  const onDismiss = () => {
    if (setRootState) {
      setRootState({
        showError: false,
        errorMessage: undefined
      });
    }
  };
  return <div className={`error-box ${className}`}>
    <img className="error-icon" height={50} src="/assets/icons/warning_black.svg"/>
    <h5 className="mb-4">
      <div dangerouslySetInnerHTML={{__html: message || 'Oops, something went wrong' }} />
    </h5>
    {!hideButton && <Button onClick={onClick || onDismiss}>{ctaLabel || 'Try Again'}</Button>}
    <p className="mt-3 link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
      <u>Contact support</u>
    </p>
  </div>
};
