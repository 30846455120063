import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { connect } from 'react-redux'
import './SuccessBox.css';

import {
  setQrCodeData,
  setQrCodeModal,
  setPreviewData,
  setPreviewModal
} from 'Manage/actions';
import ShareBox from './components/ShareBox';
import { getDirectFileLink } from 'utils/general';

const FirstTimeSucessModal = ({ domain, userProfile, dispatch, show, onHide }) => {

  const onQRCode = () => {
    dispatch(setQrCodeModal(true));
    dispatch(setQrCodeData(domain.subdomain));
  };

  // const onSendEmail = () => {
  //   dispatch(setSendEmailModal(true));
  // };

  const onClose = () => {
    onHide()
  };

  const onViewSite = (site) => {
    if (site?.subdomain) window.open(`https://${site.subdomain}`, '_blank')
    else {
      dispatch(setPreviewModal(true));
      dispatch(setPreviewData(site));
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <div className="success-box">
      <div onClick={onClose}>
        <img className="exit" src="delete.png" alt="quit" />
      </div>
      <h3>BOOM!</h3>
      <img className="celebrate" src="celebrate.gif" alt="celebrate" />
      <div>
        {domain?.subdomain ? <h5 className="domain">
          <a href={`https://${domain?.subdomain}`} target="_blank" rel="noopener noreferrer">
              {domain?.subdomain}
          </a>
        </h5>: <h5 className="domain">Your Site</h5>}
        <p className="font-20">is live</p>
        <div className='d-flex flex-column mt-2 mb-3 align-items-center'>
            <div className="mt-2 d-flex align-items-center flex-row">                {/* <Button className="me-2" variant="outline-primary" onClick={onUpdateSite}>
                  Update
                </Button> */}
              <Button onClick={() => onViewSite(domain)}>
                View Site
              </Button>
            </div>
            {domain?.subdomain && <ShareBox
              trial={!!!userProfile.productId}
              url={`https://${domain?.subdomain}`}
              directFileUrl={getDirectFileLink(`https://${domain?.subdomain}`, domain?.filename, 'pdf', domain?.lastUpdated || domain?.created)}
              onQRClick={onQRCode}
              type={domain?.type}
            />}
            <div className='d-flex align-items-center justify-items-center mt-3 gap-1 font-small'>
              <span className='d-inline'>Something broken? -</span>
              <a className='d-inline link' href={`https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/`} target="_blank">
                Read our documentation
              </a>
            </div>
          </div>

      </div>
    </div>
     </Modal>
  );
};


const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(mapDispatchToProps)(withRouter(FirstTimeSucessModal));

