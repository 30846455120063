import React from 'react';
import CustomTooltip from "../CustomTooltip";

const styles = (style) => ({
  border: 'none',
  color: '#007bff',
  fontSize: '12px',
  background: 'none',
  ...style
});

const LinkButton = ({disabled, style, onClick, label, tooltip}) => {
  const button = <button
    style={styles(style)}
    onClick={disabled ? undefined : onClick}
  >
    {label}
  </button>;

  const tooltipButton = <CustomTooltip label={tooltip}>
    {button}
  </CustomTooltip>;

  return (<React.Fragment>
    {disabled ? tooltipButton : button}
  </React.Fragment>);
};

export default LinkButton;
