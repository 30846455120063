import React from 'react'

import CustomTooltip from '../CustomTooltip'

import './SelectedFile.css'
import {FILE_TYPE_PHP} from "../CreateUpdateSiteModal/interface";

export const FILE_TYPE_PDF = 'application/pdf'
export const FILE_TYPE_HTML = 'text/html'

export const isZipFile = (type) => ['application/zip', 'application/octet-stream'].includes(type)

const FILE_ICONS = {
  [FILE_TYPE_HTML]: 'html-file.png',
  [FILE_TYPE_PDF]: 'pdf-file.png',
  [FILE_TYPE_PHP]: 'php-file.png',
  'application/zip': 'zip-folder.png',
  'application/octet-stream': 'zip-folder.png'
}

const SelectedFile = ({ file, className = '', onRemove, leftAlign }) => {
  const icon = FILE_ICONS[file.type || file.fileType] || 'zip-folder.png'

  return (
    <>
      <div className={`selected-file-container ${className}`}>
        <div className={`zip-file ${leftAlign ? 'left-align' : ''}`}>
          <img className="zip-icon" src={`assets/icons/${icon}`} alt="Zip" />
          <span className="text-break font-20">{file.path || file.name || file.fileName}</span>
          {file.type === FILE_TYPE_HTML && (
            <CustomTooltip label="Will be used as your site's homepage (index.html)">
              <img className="ms-2 me-3" src="/assets/icons/info.svg" height="15" />
            </CustomTooltip>
          )}
          <div className="cursor-pointer d-inline ms-3" onClick={onRemove}>
            <img className="icon-delete" src="delete.png" alt="delete" height="12" />
          </div>
        </div>
        {file.type === FILE_TYPE_HTML && (
          <div className={`${leftAlign ? 'text-start' : 'text-center'} font-small grey mt-2`}>
            Need to upload more files? Upload a zip file instead.
          </div>
        )}
      </div>
    </>
  )
}

export default SelectedFile
