const strings = {
  GB: {
    desc: 'Upgrade to link your custom domain, track your visits, edit your content & much more.',
    customDomainSSL: 'Custom Domains & SSL',
    customDomainSSLDesc: 'Link your own domains with SSL served over a CDN.',
    largeHosting: 'Host up to 1GB files',
    largeHostingDesc: 'Upload large zip files with images & videos.',
    passwordProtection: 'Password protection',
    passwordProtectionDesc: 'Restrict access with a password.',
    adFree: 'Ad free',
    adFreeDesc: 'Remove our branding from your uploaded content.',
    liveSitesTitle: 'Easily Manage Live Sites',
    liveSitesDesc: 'Manage all your sites through one, easy-to-use dashboard.',
    archiveTitle: 'Archive For Offline Sites',
    archiveDesc: 'Keep a repository of your offline sites.',
    faqTitle: 'FAQ',
    faqs: [
      {
        q: 'Do you support WordPress or similar CMS?',
        a: 'No we do not, we only host static websites.'
      },
      {
        q: 'What is a static website?',
        a:'A static website is a website that appears the same to all visitors and does not require a backend server or database to render it. It\'s composed of frontend only static files such as such as HTML, JavaScript and images (jpeg, png, svg etc).'
      },
      {
        q: 'Do you support e-commerce sites?',
        a: 'We do not in the traditional CMS way (WordPress, Joomla etc), but we do support static e-commerce sites.'
      },
      {
        q: 'What\'s the fastest way to reach you in case I need help?',
        a: 'Send us a message via the chat icon in the bottom right-hand corner or an email at support@tiiny.host. We\'re quick to respond and always happy to help. Our goal is to make web hosting as simple as possible.'
      },
      {
        q: 'How does your 7 day money back guarantee work?',
        a: 'We have many happy customers. However, if for some reason after you upgrade to one of our paid plans you decide that it\'s not for you, then just reach out to our friendly support within 7 days and we\'ll be happy to give you a full refund.',
      },
      {
        q: 'Can I cancel at anytime?',
        a: 'Of course! If you no longer need us, just cancel your subscription via your control panel. We process subscriptions through Stripe - which also manages thousands of other subscription services.'
      },
      {
        q: 'What happens after I cancel?',
        a: 'Your sites will remain live until the end of your billing period. After this time, we\'ll completely remove them along with your account from our servers.'
      },
    ],
    ctaTitle: 'Simplify your hosting',
    ctaBtn: 'Go pro'
  },
  BR: {
    desc: 'Atualize sua conta para hospedar sites por mais tempo e gerenciar todos os seus sites por um painel simples, e mais:',
    customDomainSSL: 'Domínios Personalizados e SSL',
    customDomainSSLDesc: 'Vincule seus domínios com SSL disponibilizado por intermédio de um CDN',
    permanentHosting: 'Hospedagem Permanente',
    permanentHostingDesc: 'Hospede sites pelo tempo que desejar.',
    passwordProtection: 'Proteção de Senha',
    passwordProtectionDesc: 'Controle o acesso aos seus sites com senha.',
    adFree: 'Sem propaganda',
    adFreeDesc: 'Remova nossa preopaganda dos seus sites hospedados.',
    liveSitesTitle: 'Gerencie facilmente seu sites online',
    liveSitesDesc: 'Gerencie todos os seus sites por meio de um painél fácil de usar.',
    archiveTitle: 'Armazenamento de sites off-line',
    archiveDesc: 'Mantenha um repositório dos seus sites offline (fora do ar).',
    faqTitle: 'Perguntas frequentes',
    faqs: [
      {
        q: 'O que são sites arquivados?',
        a: 'Você pode definir uma vida útil para um site online que você está testando, por exemplo. Assim que o site atingir o final da sua vida útil, ele será automaticamente colocado off-line e salvo em seu arquivo de sites off-line. Você pode optar por reativar ou excluir seu site. Você também pode arquivar manualmente um site a qualquer momento para colocá-lo fora do ar (off-line).'
      },
      {
        q: 'Você oferece suporte a WordPress ou outro CMS semelhante?',
        a: 'Não, apenas hospedamos sites estáticos.'
      },
      {
        q: 'O que é um site estático?',
        a: 'Um site estático é um site que tem a mesma aparência para todos os visitantes e não requer um servidor back-end ou banco de dados para funcionar. É composto apenas por arquivos estáticos de frontend, como HTML, JavaScript e imagens (jpeg, png, svg, etc).'
      },
      {
        q: 'Você oferece suporte a sites de comércio eletrônico?',
        a: 'Não da forma tradicional oferecida por CMS (WordPress, Joomla etc), mas oferecemos suporte a sites de e-commerce estáticos.'
      },
      {
        q: 'Qual é a maneira mais rápida de entrar em contato com você caso precise de ajuda?',
        a: 'Envie-nos uma mensagem por meio do ícone de bate-papo no canto inferior direito ou um e-mail para support@tiiny.host. Somos rápidos em responder e estamos sempre dispostos a ajudar. Nosso objetivo é tornar a hospedagem na web o mais simples possível.'
      },
      {
        q: 'Posso cancelar a qualquer momento?',
        a: 'Claro! Se você não precisar mais de nós, basta cancelar sua assinatura através do painel de controle. Processamos assinaturas por meio do Stripe - que também gerencia milhares de outros serviços de assinatura.'
      },
      {
        q: 'O que acontece depois que eu cancelar?',
        a: 'Seus sites permanecerão ativos até o final do período de cobrança e daremos a você 30 dias a partir do momento do cancelamento para baixar seus sites. Após este período, iremos removê-los completamente junto com seus dados de nossos servidores.'
      },
    ],
    ctaTitle: 'Simplifique sua hospedagem',
    ctaBtn: 'Go pro'
  }
}

export const SAMPLE_CODE = '<!DOCTYPE html>\n' +
  '<html lang="en">\n' +
  '<head>\n' +
  '    <meta charset="UTF-8">\n' +
  '    <title>Title</title>\n' +
  '</head>\n' +
  '<body>\n' +
  '    <h1>My Website</h1>\n' +
  '</body>\n' +
  '</html>';

export default strings;
