import { useState, useEffect } from 'react'
import styles from './CustomDomainModal.module.css'
import { Modal, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { REGEX_FULLDOMAIN, REGEX_SUBDOMAIN } from '../../constants'
import AddDomainAddress from './components/AddDomainAddress'
import DNSSetting from './components/DNSSetting'
import SetUpWizard from './components/SetUpWizard'
import DNSRecords from './components/DNSRecords'
import Success from './components/Success'

const initialState = {
  dnsAddress: {
    domain: '',
    selected: null,
    checked: false,
    subdomain: ''
  },
  path: {
    selected: null,
    domain_search: [],
    selected_domain: {
      name: "",
      price: "",
      available: false,
      status: "",
      payment_success: false,
      isPremium: false
    }
  },
  wizard: {
    selected: null
  },
  dnsRecords: {
    checked: false,
    data: null
  },
  expired: false,
  validationFailed: false,
  isSetUpComplete: false
}

const checkNoDuplicate = (domains, value = '') => !domains.includes(value)

const CustomDomainModal = ({
  show,
  onClose,
  domains = [],
  onSelectEntri,
  onFail,
  setDomains,
  refreshProfile,
  onSiteAction,
  handleCheckEntri
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const [initialValues, setInitialValues] = useState(initialState)
  const createdTime = localStorage.getItem('start_time')
  const formValues = localStorage.getItem('form_values')
  const retry = localStorage.getItem('retry')
  const validate = localStorage.getItem('validate')

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const CUSTOM_DOMAIN_SCHEMA = Yup.object().shape({
    dnsAddress: Yup.object().shape({
      domain: Yup.string()
      .matches(REGEX_FULLDOMAIN, 'Please enter a valid domain (e.g. my domain.com)')
      .required('Please enter a domain')
      .test('domain', 'Domain already added', (value) => checkNoDuplicate(domains, value)),
      selected: Yup.string().required('Please select an option'),
      checked: Yup.boolean(),
      subdomain: Yup.string().when('checked', {
        is: true,
        then: Yup.string().matches(REGEX_SUBDOMAIN, 'Please enter a valid subdomain').required('Please enter your subdomain'),
        otherwise: Yup.string()
      })
    }),
    path: Yup.object().shape({
      selected: Yup.string()
    }),
    wizard: Yup.object().shape({
      selected: Yup.string().required('Please select an option')
    }),
    dnsRecords: Yup.object().shape({
      checked: Yup.boolean().required('Please select an option')
    })
  })

  useEffect(() => {
    if (createdTime) {
      setActiveStep(4)
    }
  }, [createdTime])

  useEffect(() => {
    if (formValues) {
      setInitialValues(JSON.parse(formValues))
    }
  }, [formValues])

  useEffect(() => {
    if (retry) {
      setActiveStep(4)
    }
  }, [retry])

  useEffect(() => {
    if (validate) {
      setActiveStep(3)
    }
  }, [validate])

  const handleClose = () => {
    localStorage.removeItem('retry')
    localStorage.removeItem('form_values')
    localStorage.removeItem('start_time')
    localStorage.removeItem('validate')
    localStorage.removeItem('view_records')
    onClose()
    setActiveStep(0)
    setInitialValues(initialState)
  }

  const handleSubmit = () => {
    onSiteAction()
    handleClose()
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" show={show} centered onHide={handleClose} backdrop="static">
      <Modal.Header className={styles.header} closeButton>
        <div className={styles.progress}>
          <ProgressIndicator steps={5} activeIndex={activeStep} />
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Formik
          validateOnMount
          validateOnChange
          enableReinitialize
          initialValues={initialValues}
          validationSchema={CUSTOM_DOMAIN_SCHEMA}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            setSubmitting(false)
            resetForm()
          }}
        >
          {(formik) => {
            return (
              <div className={styles.content}>
                {activeStep === 0 && (
                  <AddDomainAddress
                    formik={formik}
                    onNext={handleNext}
                    onPrevious={handleBack}
                    activeIndex={activeStep}
                  />
                )}
                {activeStep === 1 && (
                  <DNSSetting
                    formik={formik}
                    onNext={handleNext}
                    onPrevious={handleBack}
                    activeIndex={activeStep}
                    handleCheckEntri={handleCheckEntri}
                    setActiveStep={setActiveStep}
                  />
                )}
                {activeStep === 2 && (
                  <SetUpWizard
                    onNext={handleNext}
                    onPrevious={handleBack}
                    formik={formik}
                    activeIndex={activeStep}
                    onSelectEntri={onSelectEntri}
                    onFail={onFail}
                    setActiveStep={setActiveStep}
                  />
                )}
                {activeStep === 3 && (
                  <DNSRecords
                    formik={formik}
                    onNext={handleNext}
                    onPrevious={handleBack}
                    activeIndex={activeStep}
                    updateDomains={setDomains}
                    refreshProfile={refreshProfile}
                    onFail={onFail}
                  />
                )}
                {activeStep === 4 && (
                  <Success
                    formik={formik}
                    onNext={handleNext}
                    onPrevious={handleBack}
                    activeIndex={activeStep}
                    submit={handleSubmit}
                    onFail={onFail}
                    updateDomains={setDomains}
                    onSelectEntri={onSelectEntri}
                    refreshProfile={refreshProfile}
                  />
                )}
              </div>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CustomDomainModal

const ProgressIndicator = ({ steps, activeIndex }) => {
  return (
    <div className={styles.steps}>
      {Array.from({ length: steps }, (_, index) => (
        <div
          className={`${styles.step} ${activeIndex === index && styles.activeStep} ${
            activeIndex > index && styles.completeStep
          }`}
          key={index}
        />
      ))}
    </div>
  )
}

export const OptionButton = ({ active, actionlabel, actionDesc, selectOption }) => {
  return (
    <Button
      className={`${styles.optionButton} ${active && styles.activeOption}`}
      onClick={selectOption}
      variant="outline-primary"
    >
      <div className={styles.optionButtonContent}>
        <span className={styles.option}>{actionlabel}</span>
        <span className={styles.optionSubText}>{actionDesc}</span>
      </div>
    </Button>
  )
}
