import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {hideUpgradeCardModal} from "../../actions";

import './upgrade-modal-content.css'

const UpgradeModal = ({title, show, desc, cta, onClick, dispatch}) => {
  const onUpgradeCardClick = () => {
    dispatch(hideUpgradeCardModal());

    if (onClick)
      setTimeout(onClick, 300);
  }

  return <Modal
    aria-labelledby="contained-modal-title-vcenter"
    show={show}
    onHide={() => dispatch(hideUpgradeCardModal())}
    dialogClassName="upgrade-modal-content"
    backdropClassName="upgrade-modal-backdrop"
    centered
  >
    <div className="round-8 bg-white flex">
      <div className="p-4">
        <h2 className="mb-2 bold color-purple-2 font-22">
          {title}
        </h2>
        <p className="grey">{desc}</p>
        <Button className="mt-3 tr-upgrade-card-cta" variant="purple" onClick={onUpgradeCardClick}>
          {cta || 'Upgrade'} →
        </Button>
      </div>
      <div style={{ width: 200 }} className="upgrade-modal-img upgrade-bg-purple">

      </div>
    </div>
  </Modal>
};

const mapStateToProps = ({ upgrade }) => ({
  ...upgrade,
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);
