import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from 'react-bootstrap'

import '../App.css'

export const DROPZONE_WEBSITE_TYPE = 'WEBSITE'
export const DROPZONE_PDF_TYPE = 'PDF'
export const DROPZONE_PHP_TYPE = 'PHP'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 65,
  paddingBottom: 65,
  paddingLeft: 45,
  paddingRight: 45,
  borderWidth: 3,
  borderRadius: 8,
  borderColor: '#dc9bff',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const ICONS = {
  [DROPZONE_WEBSITE_TYPE]: 'html-file.png',
  [DROPZONE_PDF_TYPE]: 'pdf-file.png',
  [DROPZONE_PHP_TYPE]: 'php-file.png'
}

const getPlaceholder = (type) => {
  const icon = ICONS[type] || 'html-file.png';
  return (
    <>
      <div className="flex">
        {type !== 'ZIP' && (
          <>
            <img className="icon faded p-2" src="/assets/icons/zip-folder.png" alt="Zip"/>
            <div className="ms-2 me-2" style={{height: '40px', borderLeft: 'solid 1px #CBCBCB'}}/>
            <img className="icon faded" src={`assets/icons/${icon}`} alt="html file"/>
          </>
        )}
        {type === 'ZIP' && (
          <img className="icon faded p-2" src="/assets/icons/zip-folder.png" alt="Zip"/>
        )}
      </div>
      <div className="mt-2">
        {<span> Drag & drop zip or <br/> single file here</span>}
        <Button
          className="mt-3 d-block ps-4 pe-4"
          variant="purple"
          style={{
            width: 200
          }}
        >
          Upload file
        </Button>
      </div>
    </>
  )
}

const StyledDropzone = ({
                          onDrop,
                          onTemplateSelected,
                          className,
                          type = DROPZONE_WEBSITE_TYPE,
                          hideTemplate,
                          goToTemplateTab,
                          acceptedFiles
                        }) => {
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxFilesize: 0.5
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject]
  )

  const handleTemplateSelected = (e) => {
    e.stopPropagation()
    onTemplateSelected({
      path: type === DROPZONE_WEBSITE_TYPE ? 'example-site.zip' : 'example-doc.pdf',
      type: type === DROPZONE_WEBSITE_TYPE ? 'application/zip' : 'application/pdf',
      isTemplate: true
    })
  }

  const onExampleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    goToTemplateTab()
  }

  return (
    <div className={`container-dropzone ${className || ''}`}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {getPlaceholder(type, handleTemplateSelected, hideTemplate)}
        <br></br>
        <span className="dark-grey">
          or use an <u onClick={onExampleClick}>example</u>
        </span>
      </div>
    </div>
  )
}

export default StyledDropzone
