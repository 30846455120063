import React, { useEffect, useState } from 'react'
import { Modal, Button, Nav, Tab } from 'react-bootstrap'
import { connect } from 'react-redux'
import { TRANSACTION_FAILED, TRANSACTION_PROCESSING, transactionInit } from '../../Pricing/actions'
import { LoadingBox } from '../LoadingBox/LoadingBox'
import { ErrorBox } from '../ErrorBox/ErrorBox'
import { PERIOD_MONTH, PERIOD_YEAR, PLANS } from '../../constants/plans'
import styles from './PaymentModal.module.css'
import PaddlePay from './components/PaddlePay'
import { ERROR_CODE_MAP } from '../../stripe.constants'
import { QUARTERLY } from '../../constants/plans/pro-student'
import StripePay from './components/Stripe/StripePay'
import StripePaypal from './components/Stripe/Paypal'
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { reCAPTCHA_CLIENT } from '../../constants'
import { enrollInEmailList, MAIL_LIST_CHECKOUT_DROP } from '../../services/email-list'
import FeatureList from "../UpgradePayModal/components/FeatureList";
import CurrencyDropdown from 'components/CurrencyDropdown/CurrencyDropDown'
import { availableCurrencies } from 'constants/pricing/currency'

const getErrorMessage = ({ declineCode, accountAlreadyExistsMessage }) => {
  return (
    accountAlreadyExistsMessage || ERROR_CODE_MAP[declineCode] || 'Sorry, unable to process your card. Please try again'
  )
}

const getOnClickAction = ({ dispatch, accountAlreadyExistsMessage }) => {
  if (accountAlreadyExistsMessage) return () => (window.location.href = '/login')
  return () => dispatch(transactionInit())
}

const getPriceLabel = (plan, period, discountPercent) => {
  const currency = plan.prices[period]?.currency || '$'
  const value = plan.prices[period]?.value || 0
  const originalPrice = plan.prices[period] && `${currency} ${value}` 
  if (discountPercent) {
    const amount = parseInt(value)
    const discount = parseFloat(discountPercent)
    const final = (discount * amount) / 100
    const finalPrice = amount - final

    return (
      <>
        <span style={{ textDecoration: 'line-through' }}>{originalPrice}</span><span className='ps-1'>{currency}{finalPrice.toFixed(2)}</span>
      </>
    )
  } else {
    return originalPrice
  }
}

const PaymentModal = ({
  show,
  onHide,
  dispatch,
  declineCode,
  transactionState,
  email,
  studentData,
  plan,
  periodProp = 'monthly',
  source,
  accountAlreadyExistsMessage,
  accountCreated,
  onCurrencySelected,
  isTrial
}) => {
  const [period, setPeriod] = useState(periodProp)
  const isLoading = transactionState === TRANSACTION_PROCESSING
  const isError = transactionState === TRANSACTION_FAILED
  const priceData = plan.prices[period] || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [discountPercent, setDiscountPercent] = useState()

  useEffect(() => setPeriod(periodProp), [periodProp])

  const handleCouponCodeChange = (e) => {
    setDiscountPercent(e)
  }

  const handleDurationChange = (duration) => {
    setPeriod(duration)
    setErrorMessage('')
  }

  const urlParams = new URLSearchParams(window.location.search)
  // const usePaddle = urlParams.get('paddle') === 'true'
  const useStripePaypal = urlParams.get('stripe-paypal') === 'true'

  const onClose = () => {
    if (email) {
      enrollInEmailList(email, MAIL_LIST_CHECKOUT_DROP) // Fire & forget
    }

    if (onHide) onHide()
  }

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.dialog}
      centered
    >
      <div className="p-3">
        <img onClick={onClose} className="link float-right" src="/delete.png" height="20" />
        <h2 className="mb-2 color-purple-2 font-22">
          <b>Sign up to {plan.label}</b>
        </h2>
        <FeatureList planId={plan.id} stage={null} list={[]}/>
        <div className={isLoading || isError ? 'd-none' : 'd-block'}>
          <div style={{ display: 'flex' }} className="p-4">
            {plan.prices.monthly && (
              <Button
                className="flex-grow-1"
                variant={period === 'monthly' ? 'outline-primary' : 'outline-secondary'}
                // onClick={() => setPeriod(PERIOD_MONTH)}
                onClick={() => handleDurationChange(PERIOD_MONTH)}
              >
                <div className="price">
                  <h6>Monthly</h6>
                  <div>
                    {getPriceLabel(plan, PERIOD_MONTH)} <span className="price-period">/ month</span>
                  </div>
                </div>
              </Button>
            )}

            {plan.prices.yearly && (
              <Button
                className="flex-grow-1 ms-2"
                variant={period === PERIOD_YEAR ? 'outline-primary' : 'outline-secondary'}
                // onClick={() => setPeriod(PERIOD_YEAR)}
                onClick={() => handleDurationChange(PERIOD_YEAR)}
              >
                <div className="price">
                  <h6>Yearly</h6>
                  <div>
                    {getPriceLabel(plan, PERIOD_YEAR)} <span className="price-period">/ year</span>
                  </div>
                  {['PRO', 'PRO_U'].includes(plan) && <div className="price-period mt-1">(2 months free)</div>}
                </div>
              </Button>
            )}

            {plan.prices[QUARTERLY] && (
              <Button className="flex-grow-1 ms-2" variant="outline-primary">
                <div className="price">
                  <h6>Quarterly</h6>
                  <div>
                    ${plan.prices[QUARTERLY].value} <span className="price-period">/ 3-months</span>
                  </div>
                </div>
              </Button>
            )}
          </div>
          <div className="indie-flower-font font-16 color-purple-light mb-4 text-center" style={{ marginTop: '-10px' }}>
            🎖️ 7 day money back guarantee
          </div>
          <div className="flex justify-content-between align-items-baseline">
          <p>
            You will be billed{' '}
            <b>
              {getPriceLabel(plan, period, discountPercent)} {plan === PLANS.PRO_STUDENT ? 'every' : ''}{' '}
              {period.toLowerCase()}
            </b>
            .
          </p>
          {isTrial && <CurrencyDropdown selectedCurrency={plan.prices.monthly?.currency} onCurrencySelected={onCurrencySelected} availableCurrencies={availableCurrencies}/>}
          </div>        
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA_CLIENT}>
          <Tab.Container defaultActiveKey="card">
            <Nav variant="pills" className={`flex-row tabs ${isLoading || isError ? 'd-none' : ''}`}>
              <Nav.Item>
                <Nav.Link eventKey="card">
                  <h2 className="fs-normal">Card</h2>
                </Nav.Link>
              </Nav.Item>
              {useStripePaypal && (
                <Nav.Item>
                  <Nav.Link eventKey="stripe-paypal">
                    <h2 className="fs-normal">Paypal</h2>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="paypal">
                  <h2 className="fs-normal">Paypal</h2>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="card">
                <StripePay
                  className={isLoading || isError ? 'd-none' : 'd-block mt-3'}
                  dispatch={dispatch}
                  priceData={priceData}
                  email={email}
                  studentData={studentData}
                  source={source}
                  productId={plan.id}
                  period={period}
                  accountCreated={accountCreated}
                  handleCouponCodeChange={handleCouponCodeChange}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="stripe-paypal" unmountOnExit={false} mountOnEnter={true}>
                <StripePaypal
                  className={isLoading || isError ? 'd-none' : 'd-block mt-3'}
                  dispatch={dispatch}
                  priceData={priceData}
                  email={email}
                  studentData={studentData}
                  source={source}
                  productId={plan.id}
                  period={period}
                  accountCreated={accountCreated}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="paypal" unmountOnExit={false} mountOnEnter={true}>
                <PaddlePay
                  className={isLoading || isError ? 'd-none' : 'd-block'}
                  dispatch={dispatch}
                  priceId={priceData.id}
                  email={email}
                  studentData={studentData}
                  paddleLink={priceData.paddleLink}
                  paddleId={priceData.paddleId}
                  transactionState={transactionState}
                  source={source}
                  productId={plan.id}
                  period={period}
                  accountCreated={accountCreated}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </GoogleReCaptchaProvider>
        <LoadingBox className={isLoading ? 'd-block' : 'd-none'} message="Processing" />
        <ErrorBox
          className={isError ? 'd-block' : 'd-none'}
          message={getErrorMessage({ declineCode, accountAlreadyExistsMessage, paddleLink: priceData.paddleLink })}
          onClick={getOnClickAction({ dispatch, accountAlreadyExistsMessage })}
        />
        <div className={styles.toc}>
          By using our service you accept our{' '}
          <a href="https://tiiny.host/termsofservice.html" target="_blank">
            <u>terms & conditions</u>
          </a>{' '}
          &{' '}
          <a href="https://tiiny.host/privacypolicy.html" target="_blank">
            <u>privacy policy</u>
          </a>
        </div>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = ({ pricing, manage }) => ({
  accountCreated: manage?.userProfile?.created,
  transactionState: pricing?.transactionState,
  declineCode: pricing?.declineCode,
  accountAlreadyExistsMessage: pricing?.accountAlreadyExistsMessage,
  isTrial: !manage?.userProfile?.productId
})

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(PaymentModal))
