import {routerMiddleware} from 'connected-react-router'
import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './reducer';
import thunk from 'redux-thunk';

export const store = (history) => createStore(
  reducer(history),
  undefined,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk
    ),
  ));
