import React, { useEffect, useState } from 'react'
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { transactionFailed, transactionInit, transactionProcessing } from '../../../../Pricing/actions'
import { createPaymentMethod, createSubscription } from '../../../../services/payment'
import { setShowPaymentModal } from 'actions'
import GoogleApplePay from './GoogleApplePay'
import styles from '../../PaymentModal.module.css'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { PLANS_ID } from '../../../../constants/plans'
import { calcUpgradeTime, trackSubscriptionUpgrade, trackSubscriptionUpgradeInfo } from '../../../../utils/analytics'
import { API_ROOT } from '../../../../constants'
import api from 'utils/api'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      border: 'solid 1px #333',
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const schema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Email is invalid').required('Email is required')
})

const getRedirectUrl = (customerId, isTrialUpgrade, discountPercentage) => {
  let url = `/pro-welcome?id=${customerId}&skipq=${isTrialUpgrade}`

  if (discountPercentage) {
    // If couponCode is provided, add it to the URL
    url += `&percentOff=${discountPercentage}`
  }

  return url
}

const PaymentModal = ({
  className,
  dispatch,
  history,
  priceData,
  email,
  studentData = {},
  source,
  productId,
  period,
  accountCreated,
  googleReCaptchaProps,
  handleCouponCodeChange,
  errorMessage,
  setErrorMessage
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const isTrialUpgrade = window.location.pathname.includes('manage')
  const { stripePriceId: priceId } = priceData
  const discountCode = localStorage.getItem('discount-code')

  const [isLoading, setIsLoading] = useState(false)
  const [isCouponValid, setIsCouponValid] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)
  const [couponCode, setCouponCode] = useState(null)
  const [couponPercent, setCouponPercent] = useState(null)

  useEffect(() => {
    // reset coupon
    handleCouponCodeChange(undefined)
    setIsCouponValid(false)
    setCouponPercent(null)
  }, [period])

  useEffect(() => {
    if (discountCode) {
      setCouponCode(discountCode)
      verifyCoupon(discountCode)
    }
  }, [discountCode, period])

  const verifyCoupon = async (couponCode) => {
    const headers = {}
    const body = {
      couponId: couponCode
    }
    if (!couponCode) {
      setErrorMessage('Please enter a coupon code')
      return
    }
    try {
      setIsLoading(true)

      const response = await api.post(`${API_ROOT}/pro/verify-coupon`, body, headers)
      if (response.data.error) {
        throw response.data
      }
      const newCoupon = response.data
      setIsCouponValid(true)
      setErrorMessage(undefined)
      setCouponPercent(parseFloat(newCoupon.data.percent_off))
      handleCouponCodeChange(newCoupon.data.percent_off)
      alert('Discount applied')

      if (localStorage) {
        localStorage.removeItem('discount-code')
      }

      return response.data
    } catch (error) {
      setErrorMessage('Invalid coupon code.')
    } finally {
      setIsLoading(false)
    }
  }

  const onSuccess = (customerId, transactionId, isTrialUpgrade, upgradeReason) => {
    dispatch(transactionInit())
    dispatch(setShowPaymentModal({showPaymentModal: false, upgradePlanId: null}))
    trackSubscriptionUpgrade(
      email,
      PLANS_ID[productId]?.label,
      PLANS_ID[productId]?.prices[period]?.value,
      period,
      transactionId,
      source
    )
    trackSubscriptionUpgradeInfo(email, calcUpgradeTime(accountCreated), upgradeReason)

    isCouponValid
      ? history.push(getRedirectUrl(customerId, isTrialUpgrade, couponPercent))
      : history.push(getRedirectUrl(customerId, isTrialUpgrade))
  }

  const handle3DSecure = (paymentIntent, paymentMethodId, customerId, subscriptionParams) => {
    stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId
      })
      .then((result) => {
        if (result.error) {
          dispatch(transactionFailed())
        } else if (result.paymentIntent.status === 'succeeded') {
          onSuccess(customerId, result?.paymentIntent?.id, isTrialUpgrade, subscriptionParams?.upgradeReason)
        }
      })
      .catch(() => {
        dispatch(transactionFailed())
      })
  }

  const processSubscription = (subscriptionParams, paymentMethodId, recaptchaValue) => {
    const headers = {
      'x-recaptcha': recaptchaValue
    }
    createSubscription({
      ...subscriptionParams,
      paymentMethodId,
      couponId: isCouponValid ? couponCode : null,
      headers
    })
      .then(async ({ data }) => {
        //success
        if (data?.subscriptionStatus === 'active') {
          // affiliate tracking
          if (window.fpr) {
            window.fpr('referral', { email: subscriptionParams.email.toLowerCase() })
          }
          onSuccess(data?.customerId, data?.paymentIntent?.id, isTrialUpgrade, subscriptionParams?.upgradeReason)
        } else if (data?.paymentStatus === 'requires_action') {
          //3D Secure
          await handle3DSecure(data?.paymentIntent, paymentMethodId, data?.customerId, subscriptionParams)
        } else {
          dispatch(transactionFailed())
        }
      })
      .catch((err) => {
        const declineCode = err?.response?.data ? err?.response?.data?.declineCode : undefined
        let accountAlreadyExistsMessage
        if (err?.response?.data?.code === 'DUPLICATE_ACCOUNT_REQUEST') {
          accountAlreadyExistsMessage = err?.response?.data?.message
        }
        dispatch(transactionFailed({ declineCode, accountAlreadyExistsMessage }))
      })
  }

  const processCardTransaction = (subscriptionParams, cardElement, recaptchaValue) => {
    createPaymentMethod(stripe, cardElement)
      .then((res) => {
        if (res.error) {
          dispatch(transactionFailed())
          return
        }
        processSubscription(subscriptionParams, res.paymentMethod.id, recaptchaValue)
      })
      .catch(() => {
        dispatch(transactionFailed())
      })
  }

  const processAppleGooglePayTransaction = async (subscriptionParams, paymentMethodId) => {
    dispatch(transactionProcessing())
    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('payment')
    processSubscription(subscriptionParams, paymentMethodId, recaptchaValue)
  }

  const onSubmit = async (formData) => {
    dispatch(transactionProcessing())
    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('payment')
    const subscriptionParams = {
      ...formData,
      name: `${formData.firstName} ${formData.lastName}`,
      priceId,
      productId,
      paymentGateway: 'STRIPE'
    }

    if (isCouponValid) {
      subscriptionParams.couponCode = couponCode
    }

    processCardTransaction(subscriptionParams, elements.getElement(CardElement), recaptchaValue)
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        email: email || studentData.email
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <form className={className} onSubmit={handleSubmit}>
          <GoogleApplePay
            priceData={priceData}
            productId={productId}
            processTransaction={processAppleGooglePayTransaction}
          />
          <Row>
            <Col>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="First name"
                value={values.firstName}
                onChange={handleChange}
              />
              <ErrorMessage name="firstName" component="div" className="validation-error" />
            </Col>
            <Col>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleChange}
              />
              <ErrorMessage name="lastName" component="div" className="validation-error" />
            </Col>
          </Row>
          {!email && (
            <Form.Control
              className="mt-2"
              name="email"
              type="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
          )}
          <ErrorMessage name="email" component="div" className="validation-error" />
          <CardElement className={styles.StripeElement} options={CARD_ELEMENT_OPTIONS} />
          {showDiscount ? (
            <>
              <InputGroup className="mt-2">
                <Form.Control
                  className=""
                  placeholder="Enter coupon code"
                  aria-label="discount"
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  onClick={() => setErrorMessage('')}
                  disabled={isLoading}
                />

              <Button size='sm' onClick={() => verifyCoupon(couponCode)} disabled={isLoading}>
                {isLoading ? 'Applying...' : 'Apply'}
              </Button>
            </InputGroup>
            {errorMessage && <span className="text-danger">{errorMessage}</span>}
          </>

        ) : (
          <div className={styles.right}>
            {!discountCode && <u className={styles.apply} onClick={()=>{setShowDiscount(true)}}>Apply for discount</u>}
          </div>
        )}
        <Form.Control
          name="upgradeReason"
          className="mt-2"
          as="select"
          value={values.upgradeReason}
          onChange={handleChange}
        >
          <option value="">Reason for upgrade...</option>
          <option value="custom-domain">Link a custom domain</option>
          <option value="more-links">Upload more projects</option>
          <option value="file-size">Upload a larger file</option>
          <option value="analytics">Analytics</option>
          <option value="editing">Editing functionalities</option>
          <option value="no-ads">Remove Tiiny Host branding</option>
          <option value="other">Other</option>
        </Form.Control>
        <div className="flex">
          <div style={{opacity: 0.4, fontSize: '14px', flex: 1}}>
            Secured with <a href="https://stripe.com" target="_blank">
            <img src="/assets/icons/stripe.svg" height="30"/>
          </a>
          </div>
          <div>
            <Button
              className="ms-auto me-auto mt-3 float-right tr-stripe-pay"
              type="submit"
              disabled={!stripe}
            >
              Pay
            </Button>
          </div>
        </div>
      </form>)
    }
  </Formik>)
};

export default withRouter(withGoogleReCaptcha(PaymentModal))
